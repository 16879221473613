var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project employ"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/CanadaEmployed/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaEmployed/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaEmployed/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaEmployed/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content1"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaEmployed/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaEmployed/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',[_vm._v("加拿大联邦自雇移民")]),_c('p',[_vm._v("文体相关技能从业者")]),_c('p',{staticClass:"time"},[_vm._v("一步到"),_c('span',[_vm._v("位拿")]),_vm._v("枫叶卡")]),_c('p',{staticClass:"tip"},[_vm._v("只为出众的你")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("加拿大联邦自雇移民")]),_c('p',{staticClass:"en"},[_vm._v("SELF-EMPLOYED PERSON PROGRAM")]),_c('p',{staticClass:"way"},[_vm._v("既快捷 • 又低价")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v("加拿大联邦自雇移民旨在吸引不会占用加拿大劳工市场的工作机会，自行以个人的才能及专业技术为自己创造工作，愿意并能够为加拿大的经济、文化或体育事业做出重大贡献的人群，以自雇形式移民加拿大。")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',[_c('h5',{staticClass:"title"},[_vm._v("在文化、艺术、娱乐、媒体、体育领域从事相关技能工作的人士")]),_c('p',[_vm._v("这类人群包括：作家、画家、艺术家、演员、运动员、室内设计师等")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer",staticStyle:{"color":"#ffffff"}},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('div',{staticClass:"text"},[_c('p',{staticClass:"item"},[_vm._v("1、费用亲民：资产要求低，仅需满足自雇和自己生活保障即可；")]),_c('p',{staticClass:"item"},[_vm._v("2、不投资、无风险：无需投资，不用创造就业；")]),_c('p',{staticClass:"item"},[_vm._v("3、一步到位：无需申请工作签证，直接获批枫叶卡，无转换身份的后顾之忧；")]),_c('p',{staticClass:"item"},[_vm._v("4、申请周期短：22个月即可获批枫叶卡。")])]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v("1、在过去五年有两年以上文化活动或体育运动的自雇经验；")]),_c('p',[_vm._v("2、意向在加拿大从事自雇，并愿意和能够为加国的文化和体育事业做出贡献；")]),_c('p',[_vm._v("3、评分系统中达到35分以上；")]),_c('p',[_vm._v("4、所有申请人体检合格和无犯罪记录。")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',[_vm._v("Q：关于自雇的定义是什么？")]),_c('p',[_vm._v("A：具备自己雇佣自己的能力，不会占用加拿大劳工市场的工作机会，自行以个人的才能及专业技术为自己创造工作，愿意并能够为加拿大的经济、文化或体育事业做出重大贡献。")]),_c('br'),_c('p',[_vm._v("Q：加拿大联邦自雇移民需要申请人拥有多高的成就？")]),_c('p',[_vm._v("A：自雇移民并不要求申请人有很高的专业成就，只需要证明自己是专业的，并在自己的专业领域内能够养活自己，拥有一定的专业追求和造诣即可。申请人过往的经历只是考核因素之一，更重要的是未来如何在加拿大开展业务，并对社会做出贡献。")])])])
}]

export { render, staticRenderFns }