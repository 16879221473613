var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/Sponsored/01.jpg`,"alt":""}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/Sponsored/03.jpg`,"alt":""}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg.jpg`,"alt":""}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{attrs:{"src":`${_vm.QNHost}/Project/Sponsored/04.jpg`,"alt":""}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',{staticClass:"no-line"},[_vm._v("省提名雇主担保移民")]),_c('p',{staticClass:"time"},[_vm._v("申请门槛低"),_c('span',[_vm._v("·")]),_vm._v("资金投入少")]),_c('p',{staticClass:"tip"},[_vm._v("以雇主为驱动的移民方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("省提名雇主担保移民")]),_c('p',{staticClass:"en"},[_vm._v("EMPLOYER SPONSORED MIGRATION")]),_c('p',{staticClass:"way"},[_vm._v("申请门槛低·无需投资")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v("省提名雇主担保移民的目的是为了帮助各省雇主吸引有技能的员工，满足各省就业市场的需求。加拿大雇主担保移民是由加拿大雇主单位主动向有关政府部门提出申请，担保其雇员获得移民身份的一种移民方式。加拿大几乎每个省都拥有自己的省提名项目，每个省的政策均有不同，目前最常见的雇主担保省份是BC省、安省、萨省等。")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',[_c('h5',{staticClass:"title"},[_vm._v("拥有雇主担保的高技能人士")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"item",staticStyle:{"line-height":"32px","font-size":"1.05rem"}},[_vm._v("1、无需投资：不用投资，雇主进行担保就能得到移民的机会，非常方便。对于很多经济能力有限的申请人而言，雇主担保移民项目是移民加拿大的最好选择。")]),_c('br'),_c('p',{staticClass:"item",staticStyle:{"line-height":"32px","font-size":"1.05rem"}},[_vm._v("2、要求宽松：虽然雇主担保移民有一定的技术以及职业方面的限制，需要通过加拿大的专门的职业测试鉴定，但与技术移民人才的标准需要达到的高相比，相对宽松。只需要有雇主愿意雇佣并提供担保。")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt",staticStyle:{"font-size":"1.3rem","line-height":"1.6rem"}},[_c('p',[_vm._v("不同省份的申请要求、申请流程和移民难度都有所不同。")]),_c('br'),_c('p',[_vm._v("申请人需要先在加拿大当地找到一个有资质，并且愿意担保你的雇主。由申请人和雇主共同向移民局提交申请。因此，这是一个需要雇主高度配合的移民途径。")]),_c('br'),_c('p',[_vm._v("一般而言，申请人需要至少拥有高中以上学历，1年以上相关工作经验，雅思4分左右的英语水平，就能符合雇主担保移民的基本要求。但因申请人较多，且名额限制，最终申请条件均高于基本要求，才会获批。")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',[_vm._v("1、省提名雇主担保移民申请容易吗？")]),_c('p',[_vm._v("如果能找到合适的雇主，移民申请成功的确定性较高。但该项目对雇主有非常严格的要求，并且需要通过移民局的资质审核，每个省份对于担保雇主的要求都有不同。雇主需要提供各种材料，证明企业有担保资格。移民局对于企业的规模、收入、税务以及运营情况都有一定要求，雇主方还需提供企业的财务报表、工资流水等隐私细节，能配合员工提供这些资料的公司并不多。")]),_c('br'),_c('p',[_vm._v("2、申请省提名雇主担保移民，对英语水平有什么要求？")]),_c('p',[_vm._v("加拿大每个省的雇主担保移民项目政策均有所不同，一般而言，只要申请人能达到雅思4分左右的英语水平，就基本能够符合要求。")])])])
}]

export { render, staticRenderFns }