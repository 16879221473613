<template>
  <div class="country america">

    <div class="top">
      <img :src="`${QNHost}/America/01.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/America/mobile/01.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h4 >泓远，<br class="mobile" />专注定制个性化美国签证方案</h4>
        <h5 >全方位海外高端定制服务诚信品牌...</h5>
        <p class="tip">轻松移民 <span> • </span> 乐居海外</p>
      </div>
    </div>

    <div class="reason">
      <img :src="`${QNHost}/America/02.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h2>为什么选择<span>美国?</span></h2>
        <ul>
          <li>
            <p>世界强国</p>
            <p>美国GDP排名全球领先，获得美国护照，即可免签全球186个国家及地区。</p>
          </li>
          <li>
            <p>顶尖教育</p>
            <p>在2020年QS世界大学综合排名TOP100中，美国院校占据29家。<br/>获得美国绿卡，在学校专业选择、奖学金申请、毕业后就业等方面，<br/>都能享受诸多优势。</p>
          </li>
          <li>
            <p>医疗先进</p>
            <p>美国具有强大的医疗科研能力、成熟的医疗技术水平，以及顶级的医疗设备。获得美国绿卡，即可享受全球领先的医疗与服务。</p>
          </li>
          <li>
            <p>科技领先</p>
            <p>自第二次世界大战以来，不论以获得诺贝尔奖及其他奖项的数量、<br/>科学论文质量，还是以创办高新技术公司的数量衡量，美国都牢牢<br/>占据世界第一的位置。</p>
          </li>
          <li>
            <p>文化优势</p>
            <p>美国自由、民主、鼓励创新的文化氛围，对申请人十分具有吸引力。</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="banner">
      <img :src="`${QNHost}/America/033.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h4>移民“美国”，不仅<span>1</span>种途径</h4>
        <p>一对一定制签证方案<br/>专注于您的个人感受</p>
      </div>
    </div>

    <div class="project item-1">
      <img :src="`${QNHost}/America/03.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/America/mobile/03.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <div class="brief">
          <h5>EB-1A杰出人才移民</h5>
          <h6>ALIEN OF EXTRAORDINARY ABILITY</h6>
          <p class="tip">高端人才的自主移民捷径</p>
          <p class="txt">EB-1A杰出人才是职业移民第一优先当中的第一类，被定义为“具有一定专业能力的水准，足以证明申请者是该领域中少数的顶尖人物之一”。</p>
          <p class="more"><span>适宜人群：</span>在科学、技术、教育、社会科学、人文学科、商业、音乐、戏剧、舞蹈、杂技、电影、文学、美术、设计、体育等等方面的杰出专业人才</p>
          <div class="btn"><a href="/americaExtraordinary">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-2">
      <img :src="`${QNHost}/America/04.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/America/mobile/04.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <div class="brief">
          <h5>EB-1C高管移民</h5>
          <h6>MANAGERS AND EXECUTIVE TRANSFEREES</h6>
          <p class="tip">移民美国的快速途径</p>
          <p class="txt">跨国企业高管移民即美国EB-1C移民，允许跨国公司将其在美国境外的高级管理人员派遣到美国的分支机构（或总部）继续从事高级管理工作。</p>
          <p class="more"><span>适宜人群：</span>跨国公司高级管理人员</p>
          <div class="btn"><a href="/americaManager">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-3">
      <img :src="`${QNHost}/America/05.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/America/mobile/05.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <div class="brief">
          <h5>EB-2/EB-3雇主担保移民</h5>
          <h6>EMPLOYMENT-BASED IMMIGRANTION</h6>
          <p class="tip">一步到位获得美国绿卡</p>
          <p class="txt">美国政府根据美国的法律，允许对任何美国劳工市场上的短缺职位，通过技术人才的资质评估，申请美国的合法永久居住权。</p>
          <p class="more"><span>适宜人群：</span>拥有硕士及以上学历的人才，或在科学、艺术、教育、商业或者运动方面拥有特别能力，对美国经济、文化、或教育有帮助的外国特殊人才</p>
          <div class="btn"><a href="/americaEmploy">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-4">
      <img :src="`${QNHost}/America/06.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/America/mobile/06.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <div class="brief">
          <h5>EB-5投资移民</h5>
          <h6>IMMIGRANT INVESTOR PROGRAM</h6>
          <p class="tip">简单有效的美国移民途径</p>
          <p class="txt">美国政府为了吸引外国投资人对美国投资、促进美国经济、增加就业机会，创建并实施了EB-5投资移民项目，属于职业移民中的第五类优先职业型移民签证。</p>
          <p class="more"><span>适宜人群：</span>提前为子女做未来规划的家庭</p>
          <div class="btn"><a href="/americaInvestor">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="blank"></div>

    <div class="banner2">
      <div class="background-media"></div>
      <div class="con">
        <h4>你移的不是过去，是<span>未来</span></h4>
        <p class="tip">快乐移民，就找泓远</p>
        <p class="tag">安心 • 省心 • 舒心 • 放心</p>
      </div>
    </div>

    <div class="project-other">
      <div class="brief">
        <h2>
          <span class="line"></span><img :src="`${QNHost}/America/10.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <p>其他热门移民项目</p>
          <img :src="`${QNHost}/America/10.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民"><span class="line"></span>
        </h2>
        <p>泓远助您 “移” 路畅通</p>
        <div class="lists">
          <div class="item item-1" @click="toPage('canadaVisa')">
            <div class="head">
              <h4> • 加拿大联邦创业投资移民 • </h4>
              <h5>START_UP VISA PEOGRAM</h5>
            </div>
            <p class="line">未来10年主流移民途径</p>
            <img :src="`${QNHost}/America/08.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          </div>
          <div class="item item-2" @click="toPage('australia_2')">
            <div class="head">
              <h4> • 188C澳洲显赫投资者签证 • </h4>
              <h5>SIGNIFICANT INVESTOR VISA</h5>
            </div>
            <p class="line">高净值人群的澳洲移民快捷通道</p>
            <img :src="`${QNHost}/America/09.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/contryAnimateMixin.js';

export default {
  name: 'america',
  mixins: [mixin]
};
</script>

<style lang="scss">
  @import '../../assets/country.scss';
</style>
