import ScrollReveal from 'scrollreveal';

export default {
  methods: {
    toPage(type) {
      this.$router.push('/' + type);
    }
  },
  mounted() {
    const scrollReveal = ScrollReveal();

    scrollReveal.reveal('.country .top .con, .country .reason .con, .country .project.item-1 .con, .country .project.item-3 .con, .country .banner2 .con', {
      distance: '500px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'right'
    });
    scrollReveal.reveal('.country .banner .con, .country .project.item-2 .con, .country .project.item-4 .con', {
      distance: '500px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'left'
    });
    scrollReveal.reveal('.country .project-other .item-1', {
      distance: '500px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'bottom'
    });
    scrollReveal.reveal('.country .project-other .item-2', {
      distance: '500px',
      duration: 1200,
      easing: 'ease-in-out',
      origin: 'bottom'
    });
  },
  beforeDestroy() {
    ScrollReveal().destroy();
  }
};