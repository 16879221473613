<template>
  <div class="project">
    <div class="top">
      <img
        :src="`${QNHost}/Project/CanadaVisa/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/CanadaVisa/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 class="no-line">加拿大联邦创业投资移民</h5>
        <p class="sub-title">创新型商业项目开发者及投资者</p>
        <p class="time">1年即<span>享永</span>居身份</p>
        <p class="tip">文件要求简单，无需解释资金来源</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/CanadaVisa/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/CanadaVisa/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="info">
          <h5>联邦创业投资移民</h5>
          <p class="en">START-UP VISA PROGRAM</p>
          <p class="way">未来10年主流移民途径</p>
          <p class="line"></p>
          <p class="txt">
            加拿大联邦创业投资移民计划的宗旨在于吸引有技术专长和创业潜能的国际人才、企业家，来加创办创新型企业和定居，帮助加拿大引进新的技术知识，为当地居民创造更多就业机会，并为经济增长和提高全球竞争力做出贡献。
          </p>
          <p class="txt">
            2017年7月，加拿大移民部公布将加拿大联邦创业投资移民计划转为正式性移民法案，该计划成为未来10年的主流移民途径已是大势所趋。
          </p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img
          :src="`${QNHost}/Project/bg.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div>
            <h5 class="title">创新型商业项目开发者和投资者</h5>
            <p>•有英语基础的企业高管、中小型企业家</p>
            <p>•海外留学生</p>
            <p>•愿意到加拿大创业的创业者</p>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <p class="item">1、文件要求简单，无需解释资金来源；</p>
          <p class="item">2、申请周期短，1年即享永居身份；</p>
          <p class="item">3、自由选择城市创业、定居；</p>
          <p class="item">4、创业成功与否不影响申请人的居留身份。</p>
          <p class="line"></p>
        </div>
        <img
          class="pc-show"
          :src="`${QNHost}/Project/CanadaVisa/04.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/CanadaVisa/mobile/04.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>1、语言能力：雅思5分及以上（听说写5分，读可以最低4分）；</p>
            <p>2、申请人须在加拿大拥有一个合格的生意；</p>
            <p>3、教育背景：全日制大学专科及以上学历；</p>
            <p>4、无犯罪记录：申请人、配偶及18岁以上的随行子女；</p>
            <p>5、身体健康：申请人及随行家庭成员。</p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p>Q：该项目需要什么背景条件？我只有大专文凭，可以申请吗 ？</p>
        <p>
          A：联邦创业投资移民项目的适应人群较广，申请人只要能满足雅思5分及以上皆可申请。
        </p>
        <br />
        <p>Q：加拿大联邦政府指定机构的支持信指什么？</p>
        <p>
          A：支持信指由政府指定机构给予项目的支持文件，包括企业孵化器（接受项目进入，给与支持信）、天使投资集团（不少于7.5万加元投资的承诺）、
          风险投资基金（不少于20万加元投资的承诺）。
        </p>
        <br />
        <p>Q：都说加拿大对移民申请文件的要求严格，我能满足吗？</p>
        <p>
          A：联邦创业投资移民项目不需要申请人申报资产，也无需提供个税、社保文件，文件要求简单，条件非常宽松，申请人大可安心。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  background-image: url($QNHost + "/Project/CanadaVisa/8.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
    background-image: url($QNHost + "/Project/CanadaVisa/mobile/8.png");
  }
}
</style>
