var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/CanadaVisa/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaVisa/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaVisa/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaVisa/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaVisa/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/CanadaVisa/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',{staticClass:"no-line"},[_vm._v("加拿大联邦创业投资移民")]),_c('p',{staticClass:"sub-title"},[_vm._v("创新型商业项目开发者及投资者")]),_c('p',{staticClass:"time"},[_vm._v("1年即"),_c('span',[_vm._v("享永")]),_vm._v("居身份")]),_c('p',{staticClass:"tip"},[_vm._v("文件要求简单，无需解释资金来源")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("联邦创业投资移民")]),_c('p',{staticClass:"en"},[_vm._v("START-UP VISA PROGRAM")]),_c('p',{staticClass:"way"},[_vm._v("未来10年主流移民途径")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v(" 加拿大联邦创业投资移民计划的宗旨在于吸引有技术专长和创业潜能的国际人才、企业家，来加创办创新型企业和定居，帮助加拿大引进新的技术知识，为当地居民创造更多就业机会，并为经济增长和提高全球竞争力做出贡献。 ")]),_c('p',{staticClass:"txt"},[_vm._v(" 2017年7月，加拿大移民部公布将加拿大联邦创业投资移民计划转为正式性移民法案，该计划成为未来10年的主流移民途径已是大势所趋。 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',[_c('h5',{staticClass:"title"},[_vm._v("创新型商业项目开发者和投资者")]),_c('p',[_vm._v("•有英语基础的企业高管、中小型企业家")]),_c('p',[_vm._v("•海外留学生")]),_c('p',[_vm._v("•愿意到加拿大创业的创业者")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"item"},[_vm._v("1、文件要求简单，无需解释资金来源；")]),_c('p',{staticClass:"item"},[_vm._v("2、申请周期短，1年即享永居身份；")]),_c('p',{staticClass:"item"},[_vm._v("3、自由选择城市创业、定居；")]),_c('p',{staticClass:"item"},[_vm._v("4、创业成功与否不影响申请人的居留身份。")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v("1、语言能力：雅思5分及以上（听说写5分，读可以最低4分）；")]),_c('p',[_vm._v("2、申请人须在加拿大拥有一个合格的生意；")]),_c('p',[_vm._v("3、教育背景：全日制大学专科及以上学历；")]),_c('p',[_vm._v("4、无犯罪记录：申请人、配偶及18岁以上的随行子女；")]),_c('p',[_vm._v("5、身体健康：申请人及随行家庭成员。")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',[_vm._v("Q：该项目需要什么背景条件？我只有大专文凭，可以申请吗 ？")]),_c('p',[_vm._v(" A：联邦创业投资移民项目的适应人群较广，申请人只要能满足雅思5分及以上皆可申请。 ")]),_c('br'),_c('p',[_vm._v("Q：加拿大联邦政府指定机构的支持信指什么？")]),_c('p',[_vm._v(" A：支持信指由政府指定机构给予项目的支持文件，包括企业孵化器（接受项目进入，给与支持信）、天使投资集团（不少于7.5万加元投资的承诺）、 风险投资基金（不少于20万加元投资的承诺）。 ")]),_c('br'),_c('p',[_vm._v("Q：都说加拿大对移民申请文件的要求严格，我能满足吗？")]),_c('p',[_vm._v(" A：联邦创业投资移民项目不需要申请人申报资产，也无需提供个税、社保文件，文件要求简单，条件非常宽松，申请人大可安心。 ")])])])
}]

export { render, staticRenderFns }