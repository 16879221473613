<template>
  <div class="project america manager">
    <div class="top">
      <img
        :src="`${QNHost}/Project/AmericaManager/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/AmericaManager/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5>EB-1C跨国企业高管移民</h5>
        <p class="tip">6个月极速登陆美国</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/AmericaManager/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/AmericaManager/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="info">
          <h5>EB-1C跨国企业高管移民</h5>
          <p class="en">MANAGERS AND EXECUTIVE TRANSFEREES</p>
          <p class="way">移民美国的快速途径</p>
          <p class="line"></p>
          <p class="txt">
            跨国企业高管移民，允许跨国公司将其在美国境外的高级管理人员，派遣到美国的分支机构（或总部）继续从事高级管理工作。
          </p>
          <p class="txt">
            首先，递交EB-1C申请的公司必须“跨国”，即美国公司必须是海外公司的母公司、分公司、子公司、合资企业或关联公司。其次，在美国的公司必须正常经营至少一年以上。另外，EB-1C移民签证受益人必须在过去的三年内在海外公司连续工作一年以上。
          </p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img
          :src="`${QNHost}/Project/bg_none.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div class="border">
            <p>公司的创始人、股东，高管（经理及以上）</p>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <div class="text">
            <p>
              <span class="star-five"></span>
              1、无需劳工卡<br />一般而言，美国聘雇移民均需要先向劳工部申请劳工证，手续繁杂又费时，但本项目的申请人则无此限制。
            </p>
            <p>
              <span class="star-five"></span>
              2、要求简单<br />无学历、语言、年龄要求，无需解释资金来源。
            </p>
            <p>
              <span class="star-five"></span>
              3、速度快<br />EB-1C是便捷获取美国绿卡的渠道。通过申请L-1，全家6个月即可先到美国工作、读书，不受排期影响改变规划。满足条件后，直接申请绿卡。
            </p>
          </div>
          <p class="line"></p>
        </div>
        <img
          class="pc-show"
          :src="`${QNHost}/Project/AmericaManager/04.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/AmericaManager/mobile/04.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>1、美国境外的公司占美国公司百分之五十一以上的股份；</p>
            <p>2、美国公司持续运营满一年；</p>
            <p>3、美国公司要满足三层以上的组织结构；</p>
            <p>4、申请人全家都没有犯罪记录，并体检合格。</p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p style="margin-bottom: 0.8rem;">
          Q：美国EB-1移民中的A、B、C三类有什么区别？
        </p>
        <p>
          A：职业移民第一类优先（简称EB-1）移民，是美国移民局为拥有杰出才能的外籍人士提供的移民途径。EB-1分为三大类，EB-1A主要针对在科学、艺术、教育、商业、或体育领域的杰出人才，申请人无需雇主担保；EB-1B主要适用于国家级或国际性声誉的教授或研究人员；EB-1C则针对被跨国公司调任到美国的高级行政人员或高级经理。
        </p>
        <br />
        <br />
        <p style="margin-bottom: 0.8rem;">Q：EB-1C和L-1是什么关系？</p>
        <p>
          A：EB-1C和L-1完全不同，但高度相关。L-1是工作签证，并不能拥有绿卡身份，最长只能在美国待7年，而EB-1C拿到的是移民签证。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  background-image: url($QNHost + "/Project/AmericaManager/7.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
    background-image: url($QNHost + "/Project/AmericaManager/mobile/11.png");
  }
}
</style>
