var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project america manager"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/AmericaManager/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaManager/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaManager/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaManager/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg_none.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaManager/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaManager/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',[_vm._v("EB-1C跨国企业高管移民")]),_c('p',{staticClass:"tip"},[_vm._v("6个月极速登陆美国")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("EB-1C跨国企业高管移民")]),_c('p',{staticClass:"en"},[_vm._v("MANAGERS AND EXECUTIVE TRANSFEREES")]),_c('p',{staticClass:"way"},[_vm._v("移民美国的快速途径")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v(" 跨国企业高管移民，允许跨国公司将其在美国境外的高级管理人员，派遣到美国的分支机构（或总部）继续从事高级管理工作。 ")]),_c('p',{staticClass:"txt"},[_vm._v(" 首先，递交EB-1C申请的公司必须“跨国”，即美国公司必须是海外公司的母公司、分公司、子公司、合资企业或关联公司。其次，在美国的公司必须正常经营至少一年以上。另外，EB-1C移民签证受益人必须在过去的三年内在海外公司连续工作一年以上。 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',{staticClass:"border"},[_c('p',[_vm._v("公司的创始人、股东，高管（经理及以上）")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('div',{staticClass:"text"},[_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 1、无需劳工卡"),_c('br'),_vm._v("一般而言，美国聘雇移民均需要先向劳工部申请劳工证，手续繁杂又费时，但本项目的申请人则无此限制。 ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 2、要求简单"),_c('br'),_vm._v("无学历、语言、年龄要求，无需解释资金来源。 ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 3、速度快"),_c('br'),_vm._v("EB-1C是便捷获取美国绿卡的渠道。通过申请L-1，全家6个月即可先到美国工作、读书，不受排期影响改变规划。满足条件后，直接申请绿卡。 ")])]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v("1、美国境外的公司占美国公司百分之五十一以上的股份；")]),_c('p',[_vm._v("2、美国公司持续运营满一年；")]),_c('p',[_vm._v("3、美国公司要满足三层以上的组织结构；")]),_c('p',[_vm._v("4、申请人全家都没有犯罪记录，并体检合格。")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v(" Q：美国EB-1移民中的A、B、C三类有什么区别？ ")]),_c('p',[_vm._v(" A：职业移民第一类优先（简称EB-1）移民，是美国移民局为拥有杰出才能的外籍人士提供的移民途径。EB-1分为三大类，EB-1A主要针对在科学、艺术、教育、商业、或体育领域的杰出人才，申请人无需雇主担保；EB-1B主要适用于国家级或国际性声誉的教授或研究人员；EB-1C则针对被跨国公司调任到美国的高级行政人员或高级经理。 ")]),_c('br'),_c('br'),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v("Q：EB-1C和L-1是什么关系？")]),_c('p',[_vm._v(" A：EB-1C和L-1完全不同，但高度相关。L-1是工作签证，并不能拥有绿卡身份，最长只能在美国待7年，而EB-1C拿到的是移民签证。 ")])])])
}]

export { render, staticRenderFns }