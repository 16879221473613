<template>
  <div class="project quebec">
    <div class="top">
      <img
        :src="`${QNHost}/Project/Canada2/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/Canada2/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 class="no-line">加拿大魁北克投资移民</h5>
        <p>资产雄厚并能合理证明资产来源的高管及股东</p>
        <p class="time">一步到<span>位拿</span>枫叶卡</p>
        <p class="tip">政府监管·项目成熟</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/Canada2/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/Canada2/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="info">
          <h5>魁北克投资移民</h5>
          <p class="en">QUEBC IMMIGRANT INVESTOR PROGRAM</p>
          <p class="way">政府监管0风险</p>
          <p class="line"></p>
          <p class="txt">
            加拿大有10个省份，而魁北克由于历史和语言的关系身份特殊，相对独立，因此在政策上魁省有属于自己独一无二的移民政策。
          </p>
          <p class="txt">
            加拿大联邦投资移民与魁北克省投资移民同步创立于1986年，是历史悠久的投资移民项目，长久以来，为商业人士移民加拿大提供了一个便捷通道。2012年联邦投资移民项目关闭后，魁北克省投资移民成为少有的加拿大投资移民项目。
          </p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img
          :src="`${QNHost}/Project/bg.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div class="border">
            <p>•大型企业高管</p>
            <p>•上市公司的股东或高管</p>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <span class="tag">安全</span>
          <p class="margin-bottom-0">·身份：先由移民局审批通过，后进行投资</p>
          <p class="margin-bottom-0">·资金：政府指定基金，投资零风险</p>
          <p class="margin-bottom-0">
            ·项目：成熟稳定，创立于1986年，已有30余年历史
          </p>
          <span class="tag">简单</span>
          <p class="margin-bottom-0">·无年龄、学历、英语要求</p>
          <p class="margin-bottom-0">
            ·无需在加拿大创办、经营企业，无需制定商业计划书
          </p>
          <p class="margin-bottom-0">
            ·一步到位获取批枫叶卡，无转换身份的后顾之忧
          </p>
          <p class="line"></p>
        </div>
        <img
          class="pc-show"
          :src="`${QNHost}/Project/Canada2/04.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/Canada2/mobile/04.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>1、资产要求：拥有超过200万加币的净资产；</p>
            <p>2、管理经验：近五年中至少拥有两年以上企业管理经验；</p>
            <p>3、资产合法来源：所有申报资产的合法来源证明；</p>
            <p>4、投资方式（二者选一）：</p>
            <p>
              <span class="star-five"></span
              >&nbsp;全额投资120万加元的投资款，五年后还本无息。
            </p>
            <p><span class="star-five"></span>&nbsp;一次性支付利息35万加元。</p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p>Q：拿到永居身份后是不是一定要住在魁北克省？</p>
        <p>
          A：申请人获得加拿大移民部批准的永久居民身份后，初落地加拿大时应该在魁北克，否则边境官员会认为你通过魁北克提名获得加拿大PR身份动机不纯。虽然当地政府或联邦政府并无硬性规定要在魁北克经营企业或居住，但我们建议第一年最好居住在魁北克。
        </p>
        <br />
        <p>Q：企业主的资产合法来源具体有什么要求？</p>
        <p>
          A：要有公司的工商注册文件等法律文件，以及企业和申请人每年的完税记录。企业主申请魁北克投资移民项目，关键在于资产积累的合理性与合法性，所以，公司的注册资金来源、公司缴税、个人缴税、企业分红记录等，都越完备越好。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  opacity: 0.6;
  background-image: url($QNHost + "/Project/Canada2/12.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
    background-image: url($QNHost + "/Project/Canada2/mobile/12.png");
  }
}
</style>
