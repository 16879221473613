var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project america investor"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/AmericaInvestor/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaInvestor/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaInvestor/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaInvestor/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg_none.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaInvestor/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaInvestor/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',{staticClass:"no-line"},[_vm._v("EB-5投资移民")]),_c('p',{staticClass:"time"},[_vm._v("一步到位"),_c('span',[_vm._v(" · ")]),_vm._v("全家绿卡")]),_c('p',{staticClass:"tip"},[_vm._v("美国第五类职业移民类别")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("EB-5投资移民")]),_c('p',{staticClass:"en"},[_vm._v("IMMIGRANT INVESTOR PROGRANM")]),_c('p',{staticClass:"way"},[_vm._v("简单有效的美国移民途径")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v(" 美国政府为了吸引外国投资人对美国投资、促进美国经济、增加就业机会，创建并实施了EB-5投资移民项目，属于职业移民中的第五类优先职业型移民签证。 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',{staticClass:"border"},[_c('p',[_vm._v("在美留学生")]),_c('p',[_vm._v("低龄赴美留学家庭")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('div',{staticClass:"text"},[_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 1、无语言、学历、经商管理等要求； ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 2、一人申请，全家 ( 配偶及21岁以下未婚子女 )均可同时获得绿卡。 ")])]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v("1、投资80万美金到美国商业项目，并创造10个就业；")]),_c('p',[_vm._v("2、说明资金合法来源以及资金转款路径。")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v(" Q：新EB-5移民签证为何没排期？ ")]),_c('p',[_vm._v(" A：美国移民局每年将分出32%的名额给城市高失业区项目、乡村项目及基建项目，与老政策下的申请通道分开。因此，新政预留项目申请通道暂无出现排期。 ")]),_c('br'),_c('br'),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v(" Q：新EB-5移民签证有何利好政策？ ")]),_c('p',[_vm._v(" A：由于新政策下预留项目通道没有排期，移民局允许在境内递交EB-5签证的申请人，可同时递交I-485转换绿卡身份。对于境内申请人，递交申请即可锁定留美合法身份。 ")])])])
}]

export { render, staticRenderFns }