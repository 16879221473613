var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project quebec"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/Canada2/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/Canada2/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/Canada2/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/Canada2/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/Canada2/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/Canada2/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',{staticClass:"no-line"},[_vm._v("加拿大魁北克投资移民")]),_c('p',[_vm._v("资产雄厚并能合理证明资产来源的高管及股东")]),_c('p',{staticClass:"time"},[_vm._v("一步到"),_c('span',[_vm._v("位拿")]),_vm._v("枫叶卡")]),_c('p',{staticClass:"tip"},[_vm._v("政府监管·项目成熟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("魁北克投资移民")]),_c('p',{staticClass:"en"},[_vm._v("QUEBC IMMIGRANT INVESTOR PROGRAM")]),_c('p',{staticClass:"way"},[_vm._v("政府监管0风险")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v(" 加拿大有10个省份，而魁北克由于历史和语言的关系身份特殊，相对独立，因此在政策上魁省有属于自己独一无二的移民政策。 ")]),_c('p',{staticClass:"txt"},[_vm._v(" 加拿大联邦投资移民与魁北克省投资移民同步创立于1986年，是历史悠久的投资移民项目，长久以来，为商业人士移民加拿大提供了一个便捷通道。2012年联邦投资移民项目关闭后，魁北克省投资移民成为少有的加拿大投资移民项目。 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',{staticClass:"border"},[_c('p',[_vm._v("•大型企业高管")]),_c('p',[_vm._v("•上市公司的股东或高管")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('span',{staticClass:"tag"},[_vm._v("安全")]),_c('p',{staticClass:"margin-bottom-0"},[_vm._v("·身份：先由移民局审批通过，后进行投资")]),_c('p',{staticClass:"margin-bottom-0"},[_vm._v("·资金：政府指定基金，投资零风险")]),_c('p',{staticClass:"margin-bottom-0"},[_vm._v(" ·项目：成熟稳定，创立于1986年，已有30余年历史 ")]),_c('span',{staticClass:"tag"},[_vm._v("简单")]),_c('p',{staticClass:"margin-bottom-0"},[_vm._v("·无年龄、学历、英语要求")]),_c('p',{staticClass:"margin-bottom-0"},[_vm._v(" ·无需在加拿大创办、经营企业，无需制定商业计划书 ")]),_c('p',{staticClass:"margin-bottom-0"},[_vm._v(" ·一步到位获取批枫叶卡，无转换身份的后顾之忧 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v("1、资产要求：拥有超过200万加币的净资产；")]),_c('p',[_vm._v("2、管理经验：近五年中至少拥有两年以上企业管理经验；")]),_c('p',[_vm._v("3、资产合法来源：所有申报资产的合法来源证明；")]),_c('p',[_vm._v("4、投资方式（二者选一）：")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 全额投资120万加元的投资款，五年后还本无息。 ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 一次性支付利息35万加元。")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',[_vm._v("Q：拿到永居身份后是不是一定要住在魁北克省？")]),_c('p',[_vm._v(" A：申请人获得加拿大移民部批准的永久居民身份后，初落地加拿大时应该在魁北克，否则边境官员会认为你通过魁北克提名获得加拿大PR身份动机不纯。虽然当地政府或联邦政府并无硬性规定要在魁北克经营企业或居住，但我们建议第一年最好居住在魁北克。 ")]),_c('br'),_c('p',[_vm._v("Q：企业主的资产合法来源具体有什么要求？")]),_c('p',[_vm._v(" A：要有公司的工商注册文件等法律文件，以及企业和申请人每年的完税记录。企业主申请魁北克投资移民项目，关键在于资产积累的合理性与合法性，所以，公司的注册资金来源、公司缴税、个人缴税、企业分红记录等，都越完备越好。 ")])])])
}]

export { render, staticRenderFns }