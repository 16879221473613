<template>
  <div class="new">
    <div class="top" ref="newTop">
      <img
        class="pc-show"
        :src="`${QNHost}/NewDetail/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/NewDetail/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 style="color:#ffffff">泓远，带您<span>走进</span>海外移民生活圈</h5>
        <p>专注大国移民 • 实现快乐移民</p>
        <p class="en">BRINGING YOU INTO THE LIFE OF</p>
        <p class="en">OVERSEAS IMMIGRANTS</p>
      </div>
    </div>

    <div class="new-nav">
      <ul>
        <li>
          <a href="/new">移民快讯 <span>></span></a>
        </li>
        <li>
          <a href="/new">泓远动态 <span>></span></a>
        </li>
        <li>
          <a href="/new">海外生活 <span>></span></a>
        </li>
      </ul>
    </div>

    <div class="new-detail">
      <div class="con">
        <h1>{{ information.name }}</h1>
        <p class="info">
          <span>泓远海外</span> <span>{{ date }}</span>
        </p>
        <section v-html="information.details"></section>
      </div>
      <div class="side-nav">
        <ul>
          <li><a href="/new">移民快讯</a></li>
          <li><a href="/new">泓远动态</a></li>
          <li><a href="/new">海外生活</a></li>
        </ul>
        <div class="nav-block" :style="setStyle">
          <p class="home">
            <a href="/">返回<br />首页</a>
          </p>
          <p class="top" @click.prevent="scrollTop">
            <a href="/"
              ><span class="arrow">></span><span>返回<br />顶部</span></a
            >
          </p>
        </div>
      </div>
      <div class="page">
        <p @click="toDetail('prev')"><span>上一篇</span><span>></span></p>
        <p @click="toDetail('next')"><span>下一篇</span><span>></span></p>
      </div>
    </div>

    <div class="about" ref="about">
      <img
        :src="`${QNHost}/Home/about.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <div class="item item-1">
          <h5>关于我们</h5>
          <p class="en">About Us</p>
          <p class="brief">
            泓远，专注高性价比【大国移民】，助您快乐移民、轻松实现海外梦。
          </p>
          <div class="btn"><a href="/about">点击查看更多></a></div>
        </div>
        <div class="item item-2">
          <h5>联系我们</h5>
          <p class="en">Contract Us</p>
          <p class="brief">
            助您快速定位适合移民国家，为您精心定制专属移民方案。
          </p>
          <div class="btn"><a href="/link">点击查看更多></a></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ScrollReveal from 'scrollreveal';
import { getInformation, informationNext } from '@/assets/api';

export default {
  name: 'detail',
  data() {
    return {
      information: {},
      createTime: '',
      date: '',
      setStyle: {
        position: 'absolute',
        top: '200px',
      },
    };
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop &&
        (document.documentElement.scrollTop = 0);
      document.body.scrollTop && (document.body.scrollTop = 0);
    },
    async getInformation(id) {
      const data = await getInformation(id);
      this.information = data.information;
      this.date = this.toDate(this.information.createTime);
      this.$nextTick(() => {
        const newTopH = this.$refs['newTop'].offsetHeight + 400;
        document.addEventListener('scroll', () => {
          const t =
            document.documentElement.scrollTop || document.body.scrollTop;
          if (t < newTopH) {
            this.setStyle = {
              position: 'absolute',
              top: '200px',
              bottom: 'auto',
            };
          } else {
            this.setStyle = {
              position: 'fixed',
              top: '100px',
              bottom: 'auto',
            };
          }
        });
      });
    },
    toDate(createTime) {
      let date = new Date(createTime * 1000);
      let m = date.getMonth() + 1;
      let d = date.getDate();
      return m + '月' + d + '日';
    },
    async toDetail(type) {
      let sort = 1;
      if (type === 'prev') {
        sort = -1;
      } else {
        sort = 1;
      }
      const data = await informationNext(this.createTime, sort);
      if (data) {
        this.information = data.informationNext;
        this.date = this.toDate(this.information.createTime);
        this.scrollTop();
        return false;
      }
    },
  },
  mounted() {
    const query = this.$route.query;
    this.getInformation(query.id);
    this.createTime = query.createTime;

    const scrollReveal = ScrollReveal();

    scrollReveal.reveal('.new .top .con', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'right',
    });
  },
};
</script>

<style lang="scss">
.new-detail {
  .con {
    img {
      max-width: 100% !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.new {
  .top {
    position: relative;
    color: #ffffff;
    z-index: -1;
    display: flex;
    img {
      width: 100%;
    }
    .con {
      position: absolute;
      top: 35%;
      left: 0;
      width: 100%;
      margin: auto;
      text-align: center;
      p {
        margin-bottom: 2rem;
        font-size: 1.3rem;
        // font-family: 'SourceHanSansCN-ExtraLight';
        &.en {
          color: #ffffff;
          opacity: 0.6;
          font-size: 0.9rem;
          margin-bottom: 8px;
        }
      }
    }
    h5 {
      font-size: 1.9rem;
      margin-bottom: 2.6rem;
      font-weight: normal;
      span {
        position: relative;
        &::before {
          content: " ";
          position: absolute;
          width: 70%;
          height: 2px;
          bottom: -22px;
          left: 44px;
          border-bottom: 6px solid #ffffff;
        }
      }
    }
  }
}

.new-nav {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: rgba(#302d2a, 0.77);
  height: 62px;
  margin-top: -62px;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1050px;
    li {
      a {
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
      }
      &:first-child a {
        color: #ecd620;
      }
      &:last-child a {
        color: #ec9320;
      }
      span {
        display: inline-block;
        margin-left: 50px;
      }
    }
  }
}

.new-detail {
  min-height: 1000px;
  position: relative;
  width: 677px;
  margin: auto;
  .con {
    margin: 80px -32px 300px;
    h1 {
      font-family: "微软雅黑";
      width: 100%;
      font-size: 1.6rem;
      margin-bottom: 8px;
      text-align: left;
    }
    .info {
      text-align: left;
      font-size: 1.1rem;
      margin-bottom: 20px;
      color: #636363;
      span:first-child {
        font-size: 1.1rem;
        display: inline-block;
        color: #1d466f;
        margin-right: 10px;
      }
    }
    section {
      width: 96%;
      background: #fff;
      padding: 20px 2%;
    }
  }
  .side-nav {
    position: absolute;
    right: -9.3rem;
    top: 0;
    height: 100%;
    border-left: 1px solid #292a2a;
    &::before {
      position: absolute;
      left: -4px;
      top: 4px;
      content: " ";
      display: block;
      width: 8px;
      height: 24px;
      background-color: #887663;
    }
    ul {
      // font-family: 'SourceHanSansCN-ExtraLight';
      padding-left: 20px;
      li {
        margin-bottom: 28px;
        a {
          text-decoration: none;
          color: #4e4e4e;
          font-size: 1.1rem;
          position: relative;
          display: block;
          letter-spacing: 3px;
          &::after {
            content: " ";
            display: block;
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 4px;
            border-bottom: 1px solid #4e4e4e;
          }
        }
      }
    }

    .nav-block {
      display: block;
      width: 5rem;
      height: 5rem;
      position: fixed;
      bottom: 0;
      z-index: 99;
      p {
        background-color: #adadad;
        display: block;
        width: 84%;
        height: 84%;
        padding: 6% 8% 8% 5%;
        cursor: pointer;
        &:last-child {
          background-color: #887663;
        }
        a {
          width: 100%;
          height: 100%;
          border: 1px solid #ffffff;
          line-height: 100%;
          color: #ffffff;
          text-decoration: none;
          word-break: break-all;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 24px;
          flex-wrap: wrap;
          span.arrow {
            display: block;
            width: 100%;
            transform: rotate(-90deg);
            line-height: 0px;
            // font-family: 'SourceHanSansCN-ExtraLight';
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.page {
  position: absolute;
  bottom: -150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  p {
    cursor: pointer;
    width: 179px;
    height: 43px;
    font-size: 20px;
    color: #ffffff;
    background-color: #b8b8b8;
    line-height: 43px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.about {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 28px;
  img {
    width: 100%;
  }
  .con {
    position: absolute;
    top: 17%;
    left: 20.5%;
    width: 65%;
    display: flex;
    justify-content: space-between;
    .item {
      width: 38%;
      background-color: rgba(#ffffff, 0.8);
      text-align: left;
      padding: 4rem 5% 3rem;
      color: #272727;
      h5 {
        font-size: 1.6rem;
        font-weight: normal;
        letter-spacing: 2px;
        margin-bottom: -5px;
      }
      .en {
        font-size: 1.3rem;
        letter-spacing: 1px;
        font-weight: normal;
        color: #686868;
      }
      .brief {
        font-size: 1rem;
        margin: 1.4rem 0 1.4rem;
        letter-spacing: 1px;
        line-height: 30px;
        // font-family: 'SourceHanSansCN-ExtraLight';
      }

      .btn {
        width: 100%;
        background-color: #545454;
        color: #ffffff;
        padding: 0.9rem 0;
        text-align: center;
        font-size: 1.2rem;
        letter-spacing: 2px;
        a {
          color: #ffffff;
          display: block;
          text-decoration: none;
        }
        &:hover {
          background-color: #363636;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .new {
    .top {
      h5 {
        font-size: 4rem;
        margin-bottom: 6.2rem;
        span {
          &::before {
            width: 7.2rem;
            height: 0.7rem;
            bottom: -1.8rem;
            left: 4.3rem;
          }
        }
      }
      .con {
        p {
          font-size: 2.6rem;
          margin-bottom: 4.4rem;
          &.en {
            font-size: 2.1rem;
            margin: 0;
          }
        }
      }
    }
  }
  .page {
    position: relative;
    bottom: 0;
    margin-top: 2rem;
    justify-content: space-around;
    p {
      width: 17.8rem;
      height: 3.9rem;
      font-size: 1.6rem;
      line-height: 3.9rem;
      padding: 0 0.2rem;
      text-align: center;
    }
  }
  .new-nav {
    margin-top: 0;
    height: 5rem;
    ul {
      width: calc(100% - 2.6rem);
      margin: 0;
      line-height: 3rem;
      li {
        a {
          font-size: 1.9rem;
        }
        span {
          margin-left: 20px;
        }
      }
    }
  }
  .new-detail {
    width: 100%;
    .con {
      margin: 0;
      width: 100%;
      h1 {
        font-size: 2.8rem;
        padding-left: 2rem;
        margin-top: 2rem;
      }
      .info {
        font-size: 2.3rem;
        padding-left: 2rem;
        margin: 0;
      }
      section {
        overflow-x: hidden;
      }
    }
    .side-nav {
      display: none;
      width: 9rem;
      right: 0;
      border-left-color: #887663;
      ul {
        display: none;
      }
      .nav-block p {
        width: 9rem;
        height: 9rem;
        a {
          line-height: 3rem;
        }
      }
    }
  }
  .about {
    display: none;
  }
}
</style>
