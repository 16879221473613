<template>
  <div class="country australia">
    <div class="top">
      <img
        :src="`${QNHost}/Australia/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile"
        :src="`${QNHost}/Australia/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h4>移居澳洲，总有一款适合您</h4>
        <h5>泓远，全方位海外高端定制服务诚信品牌</h5>
        <p class="tip">轻松移民<span> • </span> 乐居海外</p>
      </div>
    </div>

    <div class="reason">
      <img
        :src="`${QNHost}/Australia/02.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h2>为什么选择<span>澳洲?</span></h2>
        <ul>
          <li>
            <p>全球畅通无阻</p>
            <p>免签全球186个国家及地区，全球说走就走。</p>
          </li>
          <li>
            <p>生活质量高</p>
            <p>
              优质的空气和水源，安全的食物和医疗。<br />在“2019年世界最宜居城市”榜单中，澳洲的三个城市名列前十；<br />在经济合作与发展组织（OECD）的年度幸福指数排名中被评为“全<br />球最幸福的国家。
            </p>
          </li>
          <li>
            <p>最近的移民大国</p>
            <p>
              与中国多个城市有直飞航班，最快5.5小时到达。与中国时差仅2-3<br />小时，无缝监管国内生意。
            </p>
          </li>
          <li>
            <p>教育优质</p>
            <p>
              澳洲八大均位列世界大学排名百强，超过60%的大学位列世界排名<br />五百强。在“全球最适合留学的城市”榜单中，墨尔本排名第二，<br />悉尼排名第四。
            </p>
          </li>
          <li>
            <p>福利体系完善</p>
            <p>
              澳洲的社会保障体系始于1910年，被全世界公认的社会福利事业的<br />先驱。
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div class="banner">
      <!-- <img :src="`${QNHost}/Australia/03.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民"> -->
      <div class="background-media"></div>
      <div class="con">
        <h4>移民澳洲，不仅<span>1</span>种途径</h4>
        <p>一对一定制移民方案<br />专注于您的个人感受</p>
      </div>
    </div>

    <div class="project item-1">
      <img
        :src="`${QNHost}/Australia/04.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <div class="brief">
          <h5>188C澳洲显赫投资者签证</h5>
          <h6>SIGNIFICANT INVESTOR VISA</h6>
          <p class="tip">高净值人群的澳洲移民快捷通道</p>
          <p class="txt">
            澳洲188C显赫投资者签证是澳洲政府为了吸引能为澳洲经济做出巨大投资贡献的申请人特别设立的临居类签证。
          </p>
          <p class="more">
            <span>适宜人群：</span>企业主、高收入、高资产的投资人
          </p>
          <div class="btn"><a href="/australia_2">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-2">
      <img
        :src="`${QNHost}/Australia/05.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile"
        :src="`${QNHost}/Australia/mobile/05.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <div class="brief">
          <h5>188A商业创新类签证</h5>
          <h6>BUSINESSES INNOVATION AND INVESTMENT VISA</h6>
          <p class="tip">中小企业家的福音</p>
          <p class="txt">
            188A商业创新类签证属于澳洲商业技术移民，通常也被称为澳洲投资移民、澳洲商业移民等。此签证申请对审计要求相对较松、注重公司的营业额，对投资金额要求不高，投资方式可灵活变通。
          </p>
          <p class="more">
            <span>适宜人群：</span>中小型企业主或者大型企业管理者（需拥有股份）
          </p>
          <div class="btn"><a href="/australia_1">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="banner2">
      <!-- <img :src="`${QNHost}/Australia/07.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民"> -->
      <div class="background-media"></div>
      <div class="con">
        <h4>你移的不是过去，是<span>未来</span></h4>
        <p class="tip">快乐移民，就找泓远</p>
        <p class="tag">安心 • 省心 • 舒心 • 放心</p>
      </div>
    </div>

    <div class="project-other">
      <div class="brief">
        <h2>
          <span class="line"></span
          ><img
            :src="`${QNHost}/Australia/10.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <p style="margin-bottom:0px!important;">其他热门移民项目</p>
          <img
            :src="`${QNHost}/Australia/11.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          /><span class="line"></span>
        </h2>
        <p>泓远助您 “移” 路畅通</p>
        <div class="lists">
          <div class="item item-1" @click="toPage('americaExtraordinary')">
            <div class="head">
              <h4>• 美国EB-1A杰出人才移民 •</h4>
              <h5>EXTRAORDINARY ABILITY ALIENS</h5>
            </div>
            <p class="line">高端人才的极速美国移民途径</p>
            <img
              :src="`${QNHost}/Australia/08.jpg`"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
          </div>
          <div class="item item-2" @click="toPage('canadaVisa')">
            <div class="head">
              <h4>• 加拿大联邦创业投资移民 •</h4>
              <h5>START-UP VISA PROGRAM</h5>
            </div>
            <p class="line">未来10年主流移民途径</p>
            <img
              :src="`${QNHost}/Australia/09.jpg`"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/contryAnimateMixin.js';

export default {
  name: 'america',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/country.scss";
.txt {
  font-size: 16px;
}
@media screen and (max-width: 750px) {
  .country.australia .banner2 .background-media {
    background-image: url($QNHost + "/Australia/mobile/07.png");
    background-size: auto 100%;
  }
}
</style>
