var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project america manager employ"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/AmericaEmploy/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaEmploy/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"brief"},[_vm._m(2),_c('div',{staticClass:"con"},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaEmploy/02.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaEmploy/mobile/02.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})]),_vm._m(3)]),_c('div',{staticClass:"people"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Project/bg_none.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(4)])]),_c('div',{staticClass:"advantage"},[_vm._m(5),_c('div',{staticClass:"content"},[_vm._m(6),_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaEmploy/04.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/Project/AmericaEmploy/mobile/04.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}})])]),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',[_vm._v("EB-2/EB-3雇主担保移民")]),_c('p',{staticClass:"tip"},[_vm._v("简单快速的美国移民途径")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"tag"},[_vm._v("安心")]),_c('div',{staticClass:"tag"},[_vm._v("省心")]),_c('div',{staticClass:"tag"},[_vm._v("舒心")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-pic"},[_c('div',{staticClass:"background-media"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('h5',[_vm._v("EB-2/EB-3雇主担保移民")]),_c('p',{staticClass:"en"},[_vm._v("EMPLOYMENT - BASED IMMIGRATION")]),_c('p',{staticClass:"way"},[_vm._v("一步到位获得美国绿卡")]),_c('p',{staticClass:"line"}),_c('p',{staticClass:"txt"},[_vm._v(" 美国政府根据美国的法律，允许对任何美国劳工市场上的短缺职位，通过技术人才的资质评估，申请美国的合法永久居住权。 ")]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("适宜人群"),_c('span',[_vm._v("?")])]),_c('div',{staticClass:"border no-padding"},[_c('br'),_c('p',[_vm._v(" 拥有硕士及以上学历的人才，或在科学、艺术、教育、商业或者运动方面拥有特别能力，对美国经济、文化、或教育有帮助的外国特殊人才 ")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"bg-top"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"answer"},[_vm._v("项目优势"),_c('span',[_vm._v("?")])]),_c('p',{staticClass:"line"}),_c('div',{staticClass:"text"},[_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 1、一步到位"),_c('br'),_vm._v("无需转换身份，直接拿永久绿卡。对于在美国读本科，并预备读研究生的学子而言，可以免除抽H-1B工作签的烦恼，研究生毕业即获绿卡。 ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" 2、速度快"),_c('br'),_vm._v("15天内即可知晓审批结果，按照当前排期，3年内即可获批绿卡。 ")])]),_c('p',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"need"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"answer"},[_vm._v("申请要求"),_c('span',[_vm._v("?")])]),_c('div',[_c('p',{staticClass:"line"}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v("1、雇主劳工证")]),_c('p',[_vm._v("2、学历要求")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" EB-2（硕士及以上学历，或拥有学士学位以及至少5年的相关工作经验） ")]),_c('p',[_c('span',{staticClass:"star-five"}),_vm._v(" EB-3（熟练工人、学士学位持有人以及其它工人） ")])]),_c('p',{staticClass:"line"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_c('h4',[_vm._v("常见问题 "),_c('span',[_vm._v("Q&A")])]),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v(" Q：必须通过抽签拿到H-1B之后，才能做EB-2/EB-3移民？ ")]),_c('p',[_vm._v(" A：H-1B是工作签证，EB-2/EB-3是移民签证，这两者是分开的，并无任何关联。由公司支持，先拿到H-1B再做技术移民，是老一辈留学生拿到绿卡的主要途径，而申请EB-2及EB-3并不需先拿到H-1B。 ")]),_c('br'),_c('br'),_c('p',{staticStyle:{"margin-bottom":"0.8rem"}},[_vm._v(" Q：非技工类申请人文凭一定要是美国的吗？ ")]),_c('p',[_vm._v(" A：按照美国法律规定，只要拥有大学或以上4年以上的文凭，无论毕业于何处（中美英澳等地的文凭均有效），只要出自合法且受国际认可的高等学府就可以做EB-2/EB-3移民！ ")]),_c('br'),_c('p',[_vm._v("Q：EB-2/EB-3移民必须马上开始为公司工作？")]),_c('p',[_vm._v(" A：移民的过程需要3年半，在申请技术移民的过程中，实际上不能开始为公司工作。H-1B工作签证与移民签证是分开的，因此，在这段期间你可以在美国继续读书，当然也可以回国等待。而有的公司可以在此期间提供H-1B，那么工作也是可以的，法律上对这方面并没有要求。 ")])])])
}]

export { render, staticRenderFns }