<template>
  <div class="about">
    <div class="top">
      <img
        class="pc-show"
        :src="`${QNHost}/About/top.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/About/mobile/top.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 style="color:white;">泓远，<br />专注大国移民，助您快乐移民</h5>
        <p>轻松实现海外梦</p>
        <p class="en">MAKE YOUR DREAM COME TRUE EASILY</p>
      </div>
    </div>

    <div class="title">
      <div>
        <h5>关于泓远</h5>
        <h6>HOPEWINNER</h6>
      </div>
    </div>

    <div class="content">
      <div class="info">
        <p>
          泓远将服务贯穿移民的全流程，为您提供全方位专业、方位<br />细致移民申请服务，还依靠强大的海外资源，协助您安居海<br />外，并拓展国际事业，让您移居异国也能感受回家的温暖。
        </p>
<!--         <p>
          目前，泓远已经成为了一家全业务链公司，自主研发、全球<br />移民、财富管理、海外生活，环环相扣，为客户争取更高的<br />利益，并保障客户快乐移民。
        </p> -->
      </div>
      <img
        :src="`${QNHost}/About/middle.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
<!--       <div class="txt">
        <p>
          泓远从1996年起即专注于移民行业<br />经历多次政策变动，深谙各国移民政策<br />用专业的能力和诚信的态度赢得了数万家庭的信任<br />一直坚实的屹立在行业前端
        </p>
      </div> -->
    </div>

<!--     <div class="time-node">
      <img
        class="pc-show"
        :src="`${QNHost}/About/bottom.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="title">
        <h5>泓远荣誉</h5>
        <h6>HOPEWINNER</h6>
      </div>
      <img
        class="mobile-show"
        :src="`${QNHost}/About/mobile/bottom.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <ul>
        <li class="before">
          创造加拿大投资移民一次性面试8个家庭19个人记录，史无前例
        </li>
        <li class="after">创造加拿大魁北克投资移民最快纪录，9个月获移民签证</li>
        <li class="before">
          荣获加拿大魁省移民处信誉等级A级，占据四川地区70%的加拿大投资移民份额
        </li>
        <li class="after">西南地区独立开创加拿大PEI省移民计划的团队</li>
        <li class="before big">
          美国投资移民专业团队，拥有美国项目独立风险评估及监控团队
        </li>
        <li class="after">目前，自主研发美国投资移民项目申请均通过</li>
        <li class="before">目前，自主研发美国投资移民项目均全额还款</li>
        <li class="after">为澳洲投资移民（SIV）客户私人定制投资计划</li>
        <li class="before">目前，保持香港投资移民申请均通过</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';

export default {
  name: 'about',
  mounted() {
    const scrollReveal = ScrollReveal();

    scrollReveal.reveal('.about .top .con', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'left',
    });
    scrollReveal.reveal('.about > .title', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'bottom',
    });
    scrollReveal.reveal('.content .info p', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'left',
    });
    scrollReveal.reveal('.about .content .txt', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'right',
    });
    scrollReveal.reveal('.about .time-node .title', {
      duration: 1400,
      easing: 'ease-in-out',
      scale: 2,
    });
  },
  beforeDestroy() {
    ScrollReveal().destroy();
  },
};
</script>

<style lang="scss" scoped>
.about {
  .top {
    position: relative;
    color: #ffffff;
    text-align: left;
    img {
      width: 100%;
    }
    .con {
      position: absolute;
      top: 30%;
      left: 12%;
    }
    h5 {
      width: 100%;
      margin-bottom: 10px;
      font-size: 2.3rem;
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        bottom: -16px;
        left: 10px;
        width: 80px;
        height: 8px;
        background-color: #ffffff;
      }
    }
    p {
      width: 100%;
      font-size: 1.7rem;
      margin-top: 3rem;
      // font-family: 'SourceHanSansCN-ExtraLight';
      &:last-child {
        font-size: 1.3rem;
        font-family: "SourceHanSansCN-Normal";
      }
    }
  }

  .title {
    width: 100%;
    text-align: center;
    color: #171717;
    // font-family: 'SourceHanSansCN-ExtraLight';
    height: 11.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    h5 {
      position: relative;
      width: 150px;
      font-size: 1.2rem;
      font-weight: normal;
      margin: auto;
      &::before,
      &::after {
        position: absolute;
        top: 16px;
        left: -3.4rem;
        content: " ";
        display: block;
        width: 3.4rem;
        height: 1px;
        border-bottom: 1px solid #666666;
      }
      &::after {
        left: auto;
        right: -3.4rem;
      }
    }
    h6 {
      font-size: 0.9rem;
      font-weight: normal;
      margin-top: 8px;
      width: 100%;
      text-align: center;
      font-family: "SourceHanSansCN-Normal";
    }
  }

  .content {
    position: relative;
    color: #ffffff;
    img {
      width: 100%;
    }
    .info {
      position: absolute;
      left: 0;
      width: 57%;
      height: 100%;
      background-color: #171717;
      opacity: 0.81;
      text-align: left;
      p {
        margin-left: 20%;
        margin-bottom: 50px;
        font-size: 1.1rem;
        position: relative;
        line-height: 30px;
        &:first-child {
          margin-top: 12rem;
        }
        &::before {
          position: absolute;
          top: 0;
          left: -16px;
          content: " ";
          display: block;
          border-right: 2px solid #ffffff;
          height: 100%;
        }
      }
    }
    .txt {
      background: url($QNHost + "/About/bg.png") no-repeat;
      background-size: 100%;
      position: absolute;
      width: 80%;
      left: 10%;
      height: 17rem;
      bottom: -10rem;
      z-index: 999;
      &:before {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        bottom: -7rem;
        background-color: #90835f;
        z-index: -1;
      }
      p {
        margin-top: 10rem;
        line-height: 2.3rem;
        font-size: 1.3rem;
      }
    }
  }

  .time-node {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
    .title {
      position: absolute;
      top: 26.6rem;
      height: 3rem;
    }
    ul {
      position: absolute;
      top: 40rem;
      width: 1000px;
      margin: auto;
      font-size: 0.92rem;
      li {
        position: relative;
        padding-bottom: 80px;
        &.before {
          text-align: right;
          margin-right: 54%;
          &::before {
            right: -12%;
            left: auto;
          }
          &.big {
            &::before {
              right: -12.5%;
              left: auto;
            }
          }
          &::after {
            position: absolute;
            content: " ";
            display: block;
            height: 90%;
            right: -10.3%;
            left: auto;
            top: 11.5%;
            border-right: 1px solid #626262;
          }
        }
        &.after {
          text-align: left;
          margin-left: 54%;
          &::before {
            left: -9%;
          }
          &.big {
            &::before {
              left: -9.5%;
            }
          }
          &::after {
            position: absolute;
            content: " ";
            display: block;
            height: 90%;
            left: -7.5%;
            top: 11.5%;
            border-right: 1px solid #626262;
          }
        }
        &::before {
          position: absolute;
          content: " ";
          display: block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 1px solid #626262;
          background-color: #ffffff;
          z-index: 99;
        }
        &.big {
          &::before {
            width: 20px;
            height: 20px;
            background-color: #a6937c;
          }
        }
        .last {
          &::after {
            position: absolute;
            content: " ";
            display: block;
            width: 24px;
            height: 24px;
            background-color: #a6937c;
            border-radius: 50%;
            left: -10%;
            bottom: -24px;
          }
        }
      }
    }
  }
}
.background-media {
  background-image: url($QNHost + "/About/6.jpg");
}
@media screen and (max-width: 750px) {
  .about {
    .top {
      h5 {
        font-size: 3.8rem;
        margin-bottom: 5.2rem;
        &::before {
          height: 0.8rem;
          width: 7.9rem;
        }
      }
      p {
        font-size: 3.1rem;
        margin: 0;
        &.en {
          font-size: 2.4rem;
          margin-top: 8.2rem;
        }
      }
    }
    .title {
      font-size: 2.8rem;
      h5 {
        font-size: 3.4rem;
        &::before,
        &::after {
          width: 8.1rem;
        }
      }
      h6 {
        font-size: 2rem;
      }
    }
    .content {
      .info {
        top: 100px;
        height: 46rem;
        width: 100%;
        z-index: 1;
        opacity: 1;
        p {
          margin-top: 4.9rem !important;
          margin-left: 9%;
          margin-bottom: 0 !important;
          font-size: 2.1rem;
          line-height: 20px;
        }
      }
      .txt {
        width: 100%;
        height: 22.8rem;
        left: 0;
        z-index: 1;
        top: 50rem;
        p {
          font-size: 2.1rem;
          margin: 11.7rem 0 6.5rem;
          line-height: 3.2rem;
        }
      }
    }

    .time-node {
      background-color: #f0f0f0;
      img {
        width: 100%;
        margin-top: 86rem;
      }
      .title {
        top: 70rem;
      }
      ul {
        display: none;
        // top: 86rem;
        // width: 100%;
        // li {
        //   padding-bottom: 2rem;
        //   font-size: 1.9rem;
        //   &.before {
        //     width: 50%;
        //     left: 0;
        //     margin-right: 50%;
        //     &::before {
        //       right: -10%;
        //     }
        //     &::after {
        //       right: -6%;
        //     }
        //     &.big {
        //       &::before {
        //         right: -11.5%;
        //       }
        //     }
        //   }
        //   &.after {
        //     width: 45%;
        //     margin-left: 55%;
        //     padding-right: 0;
        //     &::before {
        //       left: -9%;
        //     }
        //     &::after {
        //       left: -5%;
        //     }
        //     &.big {
        //       &::before {
        //         left: -10.5%;
        //       }
        //     }
        //   }
        //   .last {
        //     &::after {
        //       left: -12%;
        //     }
        //   }
        // }
      }
    }
  }
}
</style>
