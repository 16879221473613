<template>
  <div class="new">
    <div class="top">
      <img
        class="pc-show"
        :src="`${QNHost}/New/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/New/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5>泓远，带您<span>走进</span>海外移民生活圈</h5>
        <p>专注大国移民 • 实现快乐移民</p>
        <p class="en">BRINGING YOU INTO THE LIFE OF</p>
        <p class="en">OVERSEAS IMMIGRANTS</p>
      </div>
    </div>

    <div class="consults">
      <div class="consult item-1">
        <h2><span>移民快讯</span><span>></span></h2>
        <div class="header">
          <img
            :src="`${QNHost}/New/tab_1.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <p @click="toDetail(flash.id, flash.createTime)" class="tip">
          {{ flash.name }}
        </p>
        <p @click="toDetail(flash.id, flash.createTime)" class="content">
          {{ flash.digest }}
        </p>
        <!-- <p class="more">查看更多 ></p> -->
        <ul class="list">
          <li
            v-for="(item, index) in lists.flash ? lists.flash.list : []"
            :key="index"
            @click="toDetail(item.id, item.createTime)"
          >
            <img
              :src="domainName + item.img"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
            <p>{{ item.name }}</p>
          </li>
        </ul>
        <!-- <div class="btn"><a href="/newDetail">点击阅读 ></a></div> -->
      </div>

      <div class="consult dynamic">
        <h2><span>泓远动态</span><span>></span></h2>
        <div class="header">
          <img
            :src="`${QNHost}/New/tab_2.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <p @click="toDetail(dynamic.id, dynamic.createTime)" class="tip">
          {{ dynamic.name }}
        </p>
        <p @click="toDetail(dynamic.id, dynamic.createTime)" class="content">
          {{ dynamic.digest }}
        </p>
        <!-- <p class="more">查看更多></p> -->
        <ul class="list">
          <li
            v-for="(item, index) in lists.dynamic ? lists.dynamic.list : []"
            :key="index"
            @click="toDetail(item.id, item.createTime)"
          >
            <img
              :src="domainName + item.img"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
            <p>{{ item.name }}</p>
          </li>
        </ul>
        <!-- <div class="btn"><a href="/newDetail">点击阅读 ></a></div> -->
      </div>

      <div class="consult life">
        <h2><span>海外生活</span><span>></span></h2>
        <div class="header">
          <img
            :src="`${QNHost}/New/tab_3.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <p @click="toDetail(life.id, life.createTime)" class="tip">
          {{ life.name }}
        </p>
        <p @click="toDetail(life.id, life.createTime)" class="content">
          {{ life.digest }}
        </p>
        <!-- <p class="more">查看更多 ></p> -->
        <ul class="list">
          <li
            v-for="(item, index) in lists.life ? lists.life.list : []"
            :key="index"
            @click="toDetail(item.id, item.createTime)"
          >
            <img
              :src="domainName + item.img"
              alt="成都移民公司,加拿大移民,加拿大创业投资移民"
            />
            <p>{{ item.name }}</p>
          </li>
        </ul>
        <!-- <div class="btn"><a href="/newDetail">点击阅读 ></a></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal';
import { getInformationList } from '@/assets/api';

export default {
  data() {
    return {
      lists: {},
      domainName: 'https://image.hopewinner.com/',
      flash: {},
      dynamic: {},
      life: {},
    };
  },
  methods: {
    async getLists() {
      const data = await getInformationList({ perPage: 5 });
      if (data) {
        this.flash = data.flash.list.shift();
        this.dynamic = data.dynamic.list.shift();
        this.life = data.life.list.shift();
        this.lists = data;
      }
    },
    toDetail(id, createTime) {
      this.$router.push({
        path: '/newDetail',
        query: { id, createTime },
      });
    },
  },
  mounted() {
    this.getLists();

    const scrollReveal = ScrollReveal();

    scrollReveal.reveal('.new .top .con', {
      distance: '400px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'right',
    });
    scrollReveal.reveal('.new .consults .item-1', {
      distance: '400px',
      duration: 1200,
      easing: 'ease-in-out',
      origin: 'bottom',
    });
    scrollReveal.reveal('.new .consults .dynamic', {
      distance: '400px',
      duration: 1400,
      easing: 'ease-in-out',
      origin: 'top',
    });
    scrollReveal.reveal('.new .consults .life', {
      distance: '400px',
      duration: 1600,
      easing: 'ease-in-out',
      origin: 'bottom',
    });
  },
  beforeDestroy() {
    ScrollReveal().destroy();
  },
};
</script>

<style lang="scss" scoped>
.new {
  .top {
    position: relative;
    color: #ffffff;
    z-index: -1;
    img {
      width: 100%;
    }
    .con {
      position: absolute;
      top: 35%;
      left: 0;
      width: 100%;
      margin: auto;
      text-align: center;
      p {
        margin-bottom: 2rem;
        font-size: 1.3rem;
        // font-family: 'SourceHanSansCN-ExtraLight';
        &.en {
          color: #ffffff;
          opacity: 0.6;
          font-size: 0.9rem;
          margin-bottom: 8px;
        }
      }
    }
    h5 {
      font-size: 1.9rem;
      margin-bottom: 2.6rem;
      font-weight: normal;
      color: #ffffff;
      span {
        position: relative;
        &::before {
          content: " ";
          position: absolute;
          width: 70%;
          height: 2px;
          bottom: -22px;
          left: 44px;
          border-bottom: 6px solid #ffffff;
        }
      }
    }
  }

  .consults {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 1110px;
    margin: -120px auto 100px;
    z-index: 999;
    .consult {
      width: 350px;
      text-align: left;
      background-color: #ffffff;
      box-shadow: 0 0 10px -4px #000000;
      font-size: 0.9rem;
      img {
        display: block;
        width: 100%;
      }
      h2 {
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #615750;
        padding: 10px 20px;
        font-size: 1.2rem;
        font-weight: normal;
        span:last-child {
          font-size: 2rem;
          font-weight: normal;
          font-family: "SourceHanSansCN-ExtraLight";
        }
      }
      .tip {
        color: #615750;
        padding: 36px 16px 20px;
        height: 2.6rem;
      }
      .content {
        color: #848484;
        padding: 10px 16px 20px;
        height: 8.3rem;
      }
      .more {
        color: #848484;
        padding: 10px 20px 20px;
        // font-family: 'SourceHanSansCN-ExtraLight';
        cursor: pointer;
      }
      ul {
        border-top: 1px solid #dadada;
        padding: 30px 16px 0;
        font-size: 0.8rem;
        li {
          width: 100%;
          display: flex;
          align-items: flex-start;
          color: #525252;
          margin-bottom: 30px;
          height: 84px;
          max-height: 84px;
          overflow: hidden;
          cursor: pointer;
          img {
            display: block;
            width: 127px;
            min-width: 127px;
            height: 100%;
            min-height: 100%;
            margin-right: 16px;
          }
          p {
            width: 100%;
            height: 4.4rem;
            overflow: hidden;
          }
        }
      }

      .btn {
        display: block;
        background-color: #615750;
        color: #ffffff;
        margin: 30px 20px;
        a {
          display: block;
          width: 100%;
          text-decoration: none;
          padding: 10px 0;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
        &:hover {
          opacity: 0.87;
        }
      }
      &.dynamic {
        h2,
        .btn {
          background-color: #2f496c;
        }
        .tip {
          color: #2f496c;
        }
        .btn:hover {
          opacity: 0.87;
        }
        &:after {
          background-color: #2f496c;
        }
      }
      &.life {
        h2,
        .btn {
          background-color: #682727;
        }
        .tip {
          color: #682727;
        }
        .btn:hover {
          opacity: 0.87;
        }
        &:after {
          background-color: #682727;
        }
      }
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: " ";
        width: 100%;
        height: 1rem;
        background-color: #615750;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .new {
    .top {
      h5 {
        font-size: 4rem;
        margin-bottom: 6.2rem;
        color: #ffffff;
        span {
          &::before {
            width: 7.2rem;
            height: 0.7rem;
            bottom: -1.8rem;
            left: 4.3rem;
          }
        }
      }
      .con {
        p {
          font-size: 2.6rem;
          margin-bottom: 4.4rem;
          &.en {
            font-size: 2.1rem;
            margin: 0;
          }
        }
      }
    }
    .consults {
      flex-direction: column;
      align-items: center;
      margin-top: -80px;
      width: 100%;
      .consult {
        margin-bottom: 40px;
        &::after {
          height: 4.7rem;
        }
        .content {
          height: auto;
          padding-bottom: 2rem;
        }
        ul {
          margin-bottom: 6.7rem;
          padding-top: 5rem;
          li {
            align-items: center;
            height: auto;
            margin-bottom: 2rem;
            p {
              height: auto;
              font-size: 2.1rem;
              margin: 0;
            }
            img {
              width: 22rem;
              min-width: 22rem;
              height: auto;
              margin-right: 2rem;
            }
          }
        }
      }
      .tip {
        font-size: 2.6rem;
      }
      .content {
        font-size: 2.3rem;
      }
    }
  }
}
</style>
