<template>
  <div class="project america manager employ">
    <div class="top">
      <img
        :src="`${QNHost}/Project/AmericaEmploy/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/AmericaEmploy/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5>EB-2/EB-3雇主担保移民</h5>
        <p class="tip">简单快速的美国移民途径</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/AmericaEmploy/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/AmericaEmploy/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="info">
          <h5>EB-2/EB-3雇主担保移民</h5>
          <p class="en">EMPLOYMENT - BASED IMMIGRATION</p>
          <p class="way">一步到位获得美国绿卡</p>
          <p class="line"></p>
          <p class="txt">
            美国政府根据美国的法律，允许对任何美国劳工市场上的短缺职位，通过技术人才的资质评估，申请美国的合法永久居住权。
          </p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img
          :src="`${QNHost}/Project/bg_none.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div class="border no-padding">
            <br />
            <p>
              拥有硕士及以上学历的人才，或在科学、艺术、教育、商业或者运动方面拥有特别能力，对美国经济、文化、或教育有帮助的外国特殊人才
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <div class="text">
            <p>
              <span class="star-five"></span>
              1、一步到位<br />无需转换身份，直接拿永久绿卡。对于在美国读本科，并预备读研究生的学子而言，可以免除抽H-1B工作签的烦恼，研究生毕业即获绿卡。
            </p>
            <p>
              <span class="star-five"></span>
              2、速度快<br />15天内即可知晓审批结果，按照当前排期，3年内即可获批绿卡。
            </p>
          </div>
          <p class="line"></p>
        </div>
        <img
          class="pc-show"
          :src="`${QNHost}/Project/AmericaEmploy/04.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/AmericaEmploy/mobile/04.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>1、雇主劳工证</p>
            <p>2、学历要求</p>
            <p>
              <span class="star-five"></span>
              EB-2（硕士及以上学历，或拥有学士学位以及至少5年的相关工作经验）
            </p>
            <p>
              <span class="star-five"></span>
              EB-3（熟练工人、学士学位持有人以及其它工人）
            </p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p style="margin-bottom: 0.8rem;">
          Q：必须通过抽签拿到H-1B之后，才能做EB-2/EB-3移民？
        </p>
        <p>
          A：H-1B是工作签证，EB-2/EB-3是移民签证，这两者是分开的，并无任何关联。由公司支持，先拿到H-1B再做技术移民，是老一辈留学生拿到绿卡的主要途径，而申请EB-2及EB-3并不需先拿到H-1B。
        </p>
        <br />
        <br />
        <p style="margin-bottom: 0.8rem;">
          Q：非技工类申请人文凭一定要是美国的吗？
        </p>
        <p>
          A：按照美国法律规定，只要拥有大学或以上4年以上的文凭，无论毕业于何处（中美英澳等地的文凭均有效），只要出自合法且受国际认可的高等学府就可以做EB-2/EB-3移民！
        </p>
        <br />
        <p>Q：EB-2/EB-3移民必须马上开始为公司工作？</p>
        <p>
          A：移民的过程需要3年半，在申请技术移民的过程中，实际上不能开始为公司工作。H-1B工作签证与移民签证是分开的，因此，在这段期间你可以在美国继续读书，当然也可以回国等待。而有的公司可以在此期间提供H-1B，那么工作也是可以的，法律上对这方面并没有要求。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  width: 100%;
  background-image: url($QNHost + "/Project/AmericaEmploy/10.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
    background-image: url($QNHost + "/Project/AmericaEmploy/mobile/10.png");
  }
}
</style>
