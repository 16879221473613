<template>
  <div class="country">

    <div class="top">
      <img :src="`${QNHost}/Canada/01.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/Canada/mobile/01.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h4 >移民 “枫叶国”<span>，</span> <br class="mobile" />就选泓远</h4>
        <h5 >全方位海外高端定制服务诚信品牌...</h5>
        <p class="tip">轻松移民<span> • </span> 乐居海外</p>
      </div>
    </div>

    <div class="reason">
      <img :src="`${QNHost}/Canada/02.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h2>为什么选择<span>加拿大?</span></h2>
        <ul>
          <li>
            <p>全球通行证</p>
            <p>免签全球185个国家及地区，全球说走就走。</p>
          </li>
          <li>
            <p>政府鼓励政策</p>
            <p>实行3年百万新移民，到2021年将接受35万移民。</p>
          </li>
          <li>
            <p>超高社会福利</p>
            <p>公费医疗、家庭辅助金，退休养老金、失业津贴、<br/>损伤辅助、社会救济金、牛奶金等福利制度，生活无忧 。</p>
          </li>
          <li>
            <p>优美自然环境</p>
            <p>在联合国计划开展署公布的人类发展世界报告中，加拿大<br/>连续多年蝉联"最适合人类居住的地方"。</p>
          </li>
          <li>
            <p>领先教育水平</p>
            <p>加拿大的教育体制综合了美式教育和英式教育的优点，实<br/>行免费公立教育，教学质量高。</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="banner">
      <img :src="`${QNHost}/Canada/03.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h4>移民“枫叶国”，不仅<span>1</span>种途径</h4>
        <p>一对一定制移民方案<br/>专注于您的个人感受</p>
      </div>
    </div>

    <div class="project item-1">
      <img :src="`${QNHost}/Canada/04.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/Canada/mobile/04.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <div class="brief">
          <h5>联邦创业投资移民</h5>
          <h6>START-UP VISA PROGRAM</h6>
          <p class="tip">未来10年主流移民途径</p>
          <p class="txt">加拿大联邦创业投资移民计划的宗旨在于吸引有技术专长和创业潜能的国际人才、企业家，来加创办创新型企业和定居，帮助加拿大引进新的技术知识，为当地居民创造更多就业机会，并为经济增长和提高全球竞争力做出贡献。</p>
          <p class="more"><span>适宜人群：</span>创新型商业项目开发者和投资者</p>
          <div class="btn"><a href="/canadaVisa">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-2 none">
      <img :src="`${QNHost}/Canada/11.jpg`" alt="">
      <div class="con">
        <div class="brief">
          <h5>省提名雇主担保移民</h5>
          <h6>Employer Sponsored Migration</h6>
          <p class="tip">中产阶级的良好选择</p>
          <p class="txt">雇主担保移民的目的是为了帮助各省雇主吸引有技能的员工，满足各省就业市场的需求。加拿大雇主担保移民是由加拿大雇主单位主动向有关政府部门提出申请，担保其雇员获得移民身份的一种移民方式。加拿大几乎每个省都拥有自己的省提名项目，每个省的政策均有不同，目前最常见的雇主担保省份是BC省、安省、萨省等。</p>
          <p class="more"><span>适宜人群：</span>拥有雇主担保的高技能人士</p>
          <div class="btn"><a href="/canadaSponsored">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-3">
      <img :src="`${QNHost}/Canada/06.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile" :src="`${QNHost}/Canada/mobile/06.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <div class="brief">
          <h5>魁北克投资移民</h5>
          <h6>QUEBEC IMMIGRANT INVESTOR  PROGRAM</h6>
          <p class="tip">政府监管风险低</p>
          <p class="txt">加拿大联邦投资移民与魁北克省投资移民同步创立于1986年，是历史悠久的投资移民项目，长久以来，为商业人士移民加拿大提供了一个便捷通道。</p>
          <p class="more"><span>适宜人群：</span>资产雄厚并能合理证明资产来源的高管及股东</p>
          <div class="btn"><a href="/quebec">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="project item-2">
      <img :src="`${QNHost}/Canada/05.jpg`" alt="">
      <img class="mobile" :src="`${QNHost}/Canada/mobile/05.png`" alt="">
      <div class="con">
        <div class="brief">
          <h5>联邦自雇移民</h5>
          <h6>SELF-EMPLOYED PERSON PROGRAM</h6>
          <p class="tip">既快捷 • 又低价</p>
          <p class="txt">加拿大联邦自雇移民旨在吸引不会占用加拿大劳工市场的工作机会，自行以个人的才能及专业技术为自己创造工作，愿意并能够为加拿大的经济、文化或体育事业做出重大贡献的人群，以自雇形式移民加拿大。</p>
          <p class="more"><span>适宜人群：</span>文体相关技能从业者</p>
          <div class="btn"><a href="/canadaEmployed">查看更多</a></div>
        </div>
      </div>
    </div>

    <div class="banner2">
      <img :src="`${QNHost}/Canada/07.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h4 >你移的不是过去，是<span>未来</span></h4>
        <p class="tip">快乐移民，就找泓远</p>
        <p class="tag">安心 • 省心 • 舒心 • 放心</p>
      </div>
    </div>

    <div class="project-other">
      <div class="brief">
        <h2>
          <span class="line"></span><img :src="`${QNHost}/Canada/10.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <p>其他热门移民项目</p>
          <img class="second" :src="`${QNHost}/Canada/10.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民"><span class="line"></span>
        </h2>
        <p class="tip">泓远助您 “移” 路畅通</p>
        <div class="lists">
          <div class="item item-1" @click="toPage('americaExtraordinary')">
            <div class="head">
              <h4> • 美国EB-1A杰出人才移民 • </h4>
              <h5>EXTRAORDINARY ABILITY ALIENS</h5>
            </div>
            <p class="line">高端人才的极速美国移民途径</p>
            <img :src="`${QNHost}/Canada/08.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          </div>
          <div class="item item-2"  @click="toPage('australia_2')">
            <div class="head">
              <h4> • 188C澳洲显赫投资者签证 • </h4>
              <h5>SIGNIFICANT INVESTOR VISA</h5>
            </div>
            <p class="line">高净值人群的澳洲移民快捷通道</p>
            <img :src="`${QNHost}/Canada/09.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mixin from '@/assets/contryAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin]
};
</script>

<style lang="scss" scoped>
  @import '../../assets/country.scss';
</style>
