<template>
  <div class="project employ">
    <div class="top">
      <img :src="`${QNHost}/Project/CanadaEmployed/01.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile-show" :src="`${QNHost}/Project/CanadaEmployed/mobile/01.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h5>加拿大联邦自雇移民</h5>
        <p>文体相关技能从业者</p>
        <p class="time">一步到<span>位拿</span>枫叶卡</p>
        <p class="tip">只为出众的你</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img class="pc-show" :src="`${QNHost}/Project/CanadaEmployed/02.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <img class="mobile-show" :src="`${QNHost}/Project/CanadaEmployed/mobile/02.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        </div>
        <div class="info">
          <h5>加拿大联邦自雇移民</h5>
          <p class="en">SELF-EMPLOYED PERSON PROGRAM</p>
          <p class="way">既快捷 • 又低价</p>
          <p class="line"></p>
          <p class="txt">加拿大联邦自雇移民旨在吸引不会占用加拿大劳工市场的工作机会，自行以个人的才能及专业技术为自己创造工作，愿意并能够为加拿大的经济、文化或体育事业做出重大贡献的人群，以自雇形式移民加拿大。</p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img :src="`${QNHost}/Project/bg.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div>
            <h5 class="title">在文化、艺术、娱乐、媒体、体育领域从事相关技能工作的人士</h5>
            <p>这类人群包括：作家、画家、艺术家、演员、运动员、室内设计师等</p>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content1">
        <div>
          <h4 class="answer" style="color:#ffffff">项目优势<span>?</span></h4>
          <p class="line"></p>
          <div class="text">
            <p class="item">1、费用亲民：资产要求低，仅需满足自雇和自己生活保障即可；</p>
            <p class="item">2、不投资、无风险：无需投资，不用创造就业；</p>
            <p class="item">3、一步到位：无需申请工作签证，直接获批枫叶卡，无转换身份的后顾之忧；</p>
            <p class="item">4、申请周期短：22个月即可获批枫叶卡。</p>
          </div>
          <p class="line"></p>
        </div>
        <img class="pc-show" :src="`${QNHost}/Project/CanadaEmployed/04.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        <img class="mobile-show" :src="`${QNHost}/Project/CanadaEmployed/mobile/04.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>1、在过去五年有两年以上文化活动或体育运动的自雇经验；</p>
            <p>2、意向在加拿大从事自雇，并愿意和能够为加国的文化和体育事业做出贡献；</p>
            <p>3、评分系统中达到35分以上；</p>
            <p>4、所有申请人体检合格和无犯罪记录。</p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p>Q：关于自雇的定义是什么？</p>
        <p>A：具备自己雇佣自己的能力，不会占用加拿大劳工市场的工作机会，自行以个人的才能及专业技术为自己创造工作，愿意并能够为加拿大的经济、文化或体育事业做出重大贡献。</p>
        <br/>
        <p>Q：加拿大联邦自雇移民需要申请人拥有多高的成就？</p>
        <p>A：自雇移民并不要求申请人有很高的专业成就，只需要证明自己是专业的，并在自己的专业领域内能够养活自己，拥有一定的专业追求和造诣即可。申请人过往的经历只是考核因素之一，更重要的是未来如何在加拿大开展业务，并对社会做出贡献。</p>
      </div>
    </div>

  </div>
</template>

<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin]
};
</script>

<style lang="scss" scoped>
@import '../../assets/project.scss';
.background-media {
  background-image: url($QNHost + "/Project/CanadaEmployed/13.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
  background-image: url($QNHost + "/Project/CanadaEmployed/mobile/13.png");
}
}
</style>

