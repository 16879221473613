var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new"},[_c('div',{ref:"newTop",staticClass:"top"},[_c('img',{staticClass:"pc-show",attrs:{"src":`${_vm.QNHost}/NewDetail/01.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_c('img',{staticClass:"mobile-show",attrs:{"src":`${_vm.QNHost}/NewDetail/mobile/01.png`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"new-detail"},[_c('div',{staticClass:"con"},[_c('h1',[_vm._v(_vm._s(_vm.information.name))]),_c('p',{staticClass:"info"},[_c('span',[_vm._v("泓远海外")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.date))])]),_c('section',{domProps:{"innerHTML":_vm._s(_vm.information.details)}})]),_c('div',{staticClass:"side-nav"},[_vm._m(2),_c('div',{staticClass:"nav-block",style:(_vm.setStyle)},[_vm._m(3),_c('p',{staticClass:"top",on:{"click":function($event){$event.preventDefault();return _vm.scrollTop.apply(null, arguments)}}},[_vm._m(4)])])]),_c('div',{staticClass:"page"},[_c('p',{on:{"click":function($event){return _vm.toDetail('prev')}}},[_c('span',[_vm._v("上一篇")]),_c('span',[_vm._v(">")])]),_c('p',{on:{"click":function($event){return _vm.toDetail('next')}}},[_c('span',[_vm._v("下一篇")]),_c('span',[_vm._v(">")])])])]),_c('div',{ref:"about",staticClass:"about"},[_c('img',{attrs:{"src":`${_vm.QNHost}/Home/about.jpg`,"alt":"成都移民公司,加拿大移民,加拿大创业投资移民"}}),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('h5',{staticStyle:{"color":"#ffffff"}},[_vm._v("泓远，带您"),_c('span',[_vm._v("走进")]),_vm._v("海外移民生活圈")]),_c('p',[_vm._v("专注大国移民 • 实现快乐移民")]),_c('p',{staticClass:"en"},[_vm._v("BRINGING YOU INTO THE LIFE OF")]),_c('p',{staticClass:"en"},[_vm._v("OVERSEAS IMMIGRANTS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-nav"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/new"}},[_vm._v("移民快讯 "),_c('span',[_vm._v(">")])])]),_c('li',[_c('a',{attrs:{"href":"/new"}},[_vm._v("泓远动态 "),_c('span',[_vm._v(">")])])]),_c('li',[_c('a',{attrs:{"href":"/new"}},[_vm._v("海外生活 "),_c('span',[_vm._v(">")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/new"}},[_vm._v("移民快讯")])]),_c('li',[_c('a',{attrs:{"href":"/new"}},[_vm._v("泓远动态")])]),_c('li',[_c('a',{attrs:{"href":"/new"}},[_vm._v("海外生活")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"home"},[_c('a',{attrs:{"href":"/"}},[_vm._v("返回"),_c('br'),_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/"}},[_c('span',{staticClass:"arrow"},[_vm._v(">")]),_c('span',[_vm._v("返回"),_c('br'),_vm._v("顶部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('div',{staticClass:"item item-1"},[_c('h5',[_vm._v("关于我们")]),_c('p',{staticClass:"en"},[_vm._v("About Us")]),_c('p',{staticClass:"brief"},[_vm._v(" 泓远，专注高性价比【大国移民】，助您快乐移民、轻松实现海外梦。 ")]),_c('div',{staticClass:"btn"},[_c('a',{attrs:{"href":"/about"}},[_vm._v("点击查看更多>")])])]),_c('div',{staticClass:"item item-2"},[_c('h5',[_vm._v("联系我们")]),_c('p',{staticClass:"en"},[_vm._v("Contract Us")]),_c('p',{staticClass:"brief"},[_vm._v(" 助您快速定位适合移民国家，为您精心定制专属移民方案。 ")]),_c('div',{staticClass:"btn"},[_c('a',{attrs:{"href":"/link"}},[_vm._v("点击查看更多>")])])])])
}]

export { render, staticRenderFns }