<template>
  <div class="project america investor">
    <div class="top">
      <img
        :src="`${QNHost}/Project/AmericaInvestor/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Project/AmericaInvestor/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 class="no-line">EB-5投资移民</h5>
       <!--  <p>泓远5年定制签证方案</p> -->
        <p class="time">一步到位<span> · </span>全家绿卡</p>
        <p class="tip">美国第五类职业移民类别</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img
            class="pc-show"
            :src="`${QNHost}/Project/AmericaInvestor/02.jpg`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
          <img
            class="mobile-show"
            :src="`${QNHost}/Project/AmericaInvestor/mobile/02.png`"
            alt="成都移民公司,加拿大移民,加拿大创业投资移民"
          />
        </div>
        <div class="info">
          <h5>EB-5投资移民</h5>
          <p class="en">IMMIGRANT INVESTOR PROGRANM</p>
          <p class="way">简单有效的美国移民途径</p>
          <p class="line"></p>
          <p class="txt">
            美国政府为了吸引外国投资人对美国投资、促进美国经济、增加就业机会，创建并实施了EB-5投资移民项目，属于职业移民中的第五类优先职业型移民签证。
          </p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img
          :src="`${QNHost}/Project/bg_none.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div class="border">
            <p>在美留学生</p>
            <p>低龄赴美留学家庭</p>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <div class="text">
            <p>
              <span class="star-five"></span> 1、无语言、学历、经商管理等要求；
            </p>
            <p>
              <span class="star-five"></span> 2、一人申请，全家 (
              配偶及21岁以下未婚子女 )均可同时获得绿卡。
            </p>
<!--             <p>
              <span class="star-five"></span>
              3、泓远面对当前EB-5排期长的情形，为解决排期给申请人带来的困扰，特意为所有的意向客户推出“用5年时间搏一个绿卡未来”的签证方案，使客户拥有主动选择权，抄底当下，既移民又配资。
            </p> -->
          </div>
          <p class="line"></p>
        </div>
        <img
          class="pc-show"
          :src="`${QNHost}/Project/AmericaInvestor/04.jpg`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
        <img
          class="mobile-show"
          :src="`${QNHost}/Project/AmericaInvestor/mobile/04.png`"
          alt="成都移民公司,加拿大移民,加拿大创业投资移民"
        />
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt">
            <p>1、投资80万美金到美国商业项目，并创造10个就业；</p>
            <p>2、说明资金合法来源以及资金转款路径。</p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p style="margin-bottom: 0.8rem;">
          Q：新EB-5移民签证为何没排期？
        </p>
        <p>
          A：美国移民局每年将分出32%的名额给城市高失业区项目、乡村项目及基建项目，与老政策下的申请通道分开。因此，新政预留项目申请通道暂无出现排期。
        </p>
        <br />
        <br />
        <p style="margin-bottom: 0.8rem;">
          Q：新EB-5移民签证有何利好政策？
        </p>
        <p>
          A：由于新政策下预留项目通道没有排期，移民局允许在境内递交EB-5签证的申请人，可同时递交I-485转换绿卡身份。对于境内申请人，递交申请即可锁定留美合法身份。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin],
};
</script>

<style lang="scss" scoped>
@import "../../assets/project.scss";
.background-media {
  background-image: url($QNHost + "/Project/AmericaInvestor/9.jpg");
}
@media screen and (max-width: 750px) {
  .background-media {
    background-image: url($QNHost + "/Project/AmericaInvestor/mobile/9.png");
  }
}
</style>
