<template>
  <div class="project">
    <div class="top">
      <img :src="`${QNHost}/Project/Sponsored/01.jpg`" alt="">
      <div class="con">
        <h5 class="no-line">省提名雇主担保移民</h5>
        <p class="time">申请门槛低<span>·</span>资金投入少</p>
        <p class="tip">以雇主为驱动的移民方式</p>
      </div>
    </div>

    <div class="tags">
      <div class="con">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="brief">
      <div class="background-pic">
        <div class="background-media"></div>
      </div>
      <div class="con">
        <div class="img">
          <img :src="`${QNHost}/Project/Sponsored/03.jpg`" alt="">
        </div>
        <div class="info">
          <h5>省提名雇主担保移民</h5>
          <p class="en">EMPLOYER SPONSORED MIGRATION</p>
          <p class="way">申请门槛低·无需投资</p>
          <p class="line"></p>
          <p class="txt">省提名雇主担保移民的目的是为了帮助各省雇主吸引有技能的员工，满足各省就业市场的需求。加拿大雇主担保移民是由加拿大雇主单位主动向有关政府部门提出申请，担保其雇员获得移民身份的一种移民方式。加拿大几乎每个省都拥有自己的省提名项目，每个省的政策均有不同，目前最常见的雇主担保省份是BC省、安省、萨省等。</p>
          <p class="line"></p>
        </div>
      </div>
      <div class="people">
        <img :src="`${QNHost}/Project/bg.jpg`" alt="">
        <div class="content">
          <h4 class="answer">适宜人群<span>?</span></h4>
          <div>
            <h5 class="title">拥有雇主担保的高技能人士</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="top"><div class="bg-top"></div></div>
      <div class="content">
        <div>
          <h4 class="answer">项目优势<span>?</span></h4>
          <p class="line"></p>
          <p class="item" style="line-height: 32px; font-size: 1.05rem;">1、无需投资：不用投资，雇主进行担保就能得到移民的机会，非常方便。对于很多经济能力有限的申请人而言，雇主担保移民项目是移民加拿大的最好选择。</p>
          <br/>
          <p class="item" style="line-height: 32px; font-size: 1.05rem;">2、要求宽松：虽然雇主担保移民有一定的技术以及职业方面的限制，需要通过加拿大的专门的职业测试鉴定，但与技术移民人才的标准需要达到的高相比，相对宽松。只需要有雇主愿意雇佣并提供担保。</p>
          <p class="line"></p>
        </div>
        <img :src="`${QNHost}/Project/Sponsored/04.jpg`" alt="">
      </div>
    </div>

    <div class="need">
      <div class="content">
        <h4 class="answer">申请要求<span>?</span></h4>
        <div>
          <p class="line"></p>
          <div class="txt" style="font-size: 1.3rem; line-height: 1.6rem;">
            <p>不同省份的申请要求、申请流程和移民难度都有所不同。</p>
            <br/>
            <p>申请人需要先在加拿大当地找到一个有资质，并且愿意担保你的雇主。由申请人和雇主共同向移民局提交申请。因此，这是一个需要雇主高度配合的移民途径。</p>
            <br/>
            <p>一般而言，申请人需要至少拥有高中以上学历，1年以上相关工作经验，雅思4分左右的英语水平，就能符合雇主担保移民的基本要求。但因申请人较多，且名额限制，最终申请条件均高于基本要求，才会获批。</p>
          </div>
          <p class="line"></p>
        </div>
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p>1、省提名雇主担保移民申请容易吗？</p>
        <p>如果能找到合适的雇主，移民申请成功的确定性较高。但该项目对雇主有非常严格的要求，并且需要通过移民局的资质审核，每个省份对于担保雇主的要求都有不同。雇主需要提供各种材料，证明企业有担保资格。移民局对于企业的规模、收入、税务以及运营情况都有一定要求，雇主方还需提供企业的财务报表、工资流水等隐私细节，能配合员工提供这些资料的公司并不多。</p>
        <br/>
        <p>2、申请省提名雇主担保移民，对英语水平有什么要求？</p>
        <p>加拿大每个省的雇主担保移民项目政策均有所不同，一般而言，只要申请人能达到雅思4分左右的英语水平，就基本能够符合要求。</p>
      </div>
    </div>

  </div>
</template>
<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin]
};
</script>

<style lang="scss" scoped>
@import '../../assets/project.scss';
.background-media {
  background-image: url($QNHost + "/Project/Sponsored/02.jpg");
}
.project .top .con .time span::after {
  width: 40px;
  bottom: -21px;
  left: -10px;
}
</style>
