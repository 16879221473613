<template>
  <div class="link">
    <div class="top">
      <img
        class="pc-show"
        :src="`${QNHost}/Link/01.jpg`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <img
        class="mobile-show"
        :src="`${QNHost}/Link/mobile/01.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="con">
        <h5 style="color:white;">泓远海外</h5>
        <p>助您快速定位适合移民国家</p>
        <p>为您精心定制专属移民方案</p>
        <p class="line"></p>
        <p class="en">HOPEWINNER</p>
      </div>
    </div>

    <div class="middle">
      <div class="background-media"></div>
      <div class="title">
        <h5 style="color:#ffffff">联系泓远</h5>
        <p>HOPEWINNER</p>
      </div>
    </div>

    <div class="addr">
      <img
        :src="`${QNHost}/Link/05.png`"
        alt="成都移民公司,加拿大移民,加拿大创业投资移民"
      />
      <div class="info">
        <div class="con">
          <p class="title">
            泓远海外
          </p>
          <i class="HOPEWINNER">
            HOPEWINNER
          </i>
          <p class="line"></p>
          <p class="address">
            <span class="icons icon-addr"></span>加拿大地址：103-7240 Woodbine
            Ave, Markham, ON L3R 1A4<span class="tel tel1"
              >Tel：+1-416-996 7309</span
            >
          </p>
          <p class="address">
            <span class="icons icon-addr"></span>美国地址：4582 Havenhurst
            Cir,Irvine ,CA，USA 92604<span class="tel tel2"
              >Tel：+1-949-556 0329</span
            >
          </p>
          <p class="address">
            <span class="icons icon-addr"></span
            >中国地址：成都市高新区天晖路360号晶科一号310室<span
              class="tel tel3"
              >Tel: +86 28 8337 2981
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="form">
      <span class="headtext">
        一步到位永居身份<br />
        快速开启移民之旅
      </span>
      <div class="bottom">
        <form>
          <span
            >为了给您提供最适合您的移民方式，请填写以下表格与我们取得联系</span
          ><br />
          <div class="inputtext">
            <span>称呼 :</span>
            <input
              placeholder="称呼"
              type="text"
              name="name"
              v-model="user.name"
            />
            <span>电话 :</span>
            <input
              type="text"
              placeholder="电话"
              name="phone"
              v-model="user.phone"
            /><br />
          </div>
          <div class="countryed">
            <span style="font-weight: bold">我感兴趣的国家：（可多选）</span
            ><br />
            <div>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="加拿大"
                />加拿大
              </span>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="美国"
                />美国
              </span>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="澳大利亚"
                />澳大利亚
              </span>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="英国"
                />英国
              </span>
            </div>
            <div>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="葡萄牙"
                />葡萄牙
              </span>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="土耳其"
                />土耳其
              </span>
              <span>
                <input
                  type="checkbox"
                  v-model="user.country"
                  value="其他"
                />其他
              </span>
            </div>
          </div>
          <div class="yours">
            <span style="font-weight: bold">您的学历：</span>
            <div>
              <span>
                <input
                  type="radio"
                  v-model="user.education"
                  name="record"
                  value="高中"
                />高中
              </span>
              <span>
                <input
                  type="radio"
                  v-model="user.education"
                  name="record"
                  value="大专"
                />大专
              </span>
              <span>
                <input
                  type="radio"
                  v-model="user.education"
                  name="record"
                  value="大学"
                />大学
              </span>
              <span>
                <input
                  type="radio"
                  v-model="user.education"
                  name="record"
                  value="研究生"
                />研究生
              </span>
              <span>
                <input
                  type="radio"
                  v-model="user.education"
                  name="record"
                  value="博士"
                />博士
              </span>
            </div>
          </div>
          <div class="button" @click="createForm">
            <a-spin v-if="loading"></a-spin>开始测评
          </div>
          <br />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";
import { createForm } from "../../assets/api/form";

export default {
  name: "link",
  data() {
    return {
      user: {
        name: "",
        phone: "",
        country: [],
        education: "",
      },
      loading: false
    };
  },
  methods: {
    async createForm() {
      if (this.loading) return;
      if (
        this.user.name &&
        this.user.phone &&
        this.user.country &&
        this.user.education
      ) {
        this.loading = true;
        const resp = await createForm(this.user);
        this.loading = false;
        if (resp && resp.createForm) {
          this.user = {};
          this.$notification.success({
            message: "提交成功！",
          });
          this.$store.dispatch("setVisible", true);
        }
      } else {
        alert("请输入您的完整信息！");
      }
    },
  },
  mounted() {
    const scrollReveal = ScrollReveal();

    scrollReveal.reveal(
      ".link .top .con, .link .middle .title, .link .addr .info",
      {
        distance: "400px",
        duration: 1000,
        easing: "ease-in-out",
        origin: "left",
      }
    );
    scrollReveal.reveal(".link .imgs .item", {
      distance: "400px",
      duration: 1000,
      easing: "ease-in-out",
      origin: "right",
    });
    scrollReveal.reveal(".link .map", {
      distance: "400px",
      duration: 1000,
      easing: "ease-in-out",
      origin: "left",
    });
  },

  async addForm() {
    const valid = await (
      this.$refs.form &
      {
        validate: () => Boolean,
      }
    ).validate();

    if (valid) {
      this.sendContent = true;
    }
  },

  getContent(content) {
    this.sendContent = false;
    let params = this.formData;
    params.details = content;
    delete params.id;
    let handle = createForm;
    handle(params, this.id).then((data) => {
      if (data) {
        this.$Message.success("添加成功！");
        this.goBack();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.link {
  .top {
    position: relative;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
    .con {
      position: absolute;
      top: 32%;
      left: 12%;
      text-align: left;
      color: #ffffff;
      h5 {
        font-size: 2.7rem;
        margin-bottom: 24px;
        font-weight: normal;
      }
      p {
        color: #ffffff;
        font-size: 1.4rem;
        margin-bottom: 2rem;
        // font-family: 'SourceHanSansCN-ExtraLight';
        margin-bottom: 8px;
        &.line {
          width: 86px;
          height: 8px;
          background-color: #ffffff;
          margin-top: 2.1rem;
        }
        &.en {
          opacity: 0.6;
          font-family: "SourceHanSansCN-Normal";
          margin-top: 2.8rem;
        }
      }
    }
  }

  .middle {
    position: relative;
    display: flex;
    height: 11rem;
    .title {
      position: absolute;
      width: 100%;
      top: 30%;
      text-align: center;
      font-size: 23px;
      color: #ffffff;
      p {
        font-size: 14px;
      }
      h5 {
        position: relative;
        font-weight: normal;
        margin-bottom: 4px;
        &::after,
        &::before {
          content: " ";
          display: block;
          position: absolute;
          left: 40%;
          top: 16px;
          width: 5.5%;
          border-top: 1px solid #ffffff;
        }
        &::before {
          left: auto;
          right: 40.5%;
        }
      }
    }
  }
  .tel1 {
    margin-left: 60px;
  }
  .tel2 {
    margin-left: 118px;
  }
  .tel3 {
    margin-left: 87px;
  }
  .HOPEWINNER {
    position: absolute;
    top: 25%;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #525d82;
    z-index: -1;
  }
  .address {
    margin-top: 18px;
  }
  .addr {
    position: relative;
    color: #ffffff;
    display: flex;
    img {
      width: 100%;
      min-height: 260px;
    }
    .info {
      position: absolute;
      top: 0;
      left: 0;
      height: 18.6rem;
      border: 1px solid #37466a;
      margin: 1rem;
      text-align: left;
      font-size: 20px;
      .con {
        padding-left: 10rem;
        padding-top: 2.5rem;
        font-size: 1.3rem;
        color: #ffffff;
        .line {
          position: relative;
          display: block;
          width: 31rem;
          height: 20px;
          border-top: 1px solid #ffffff;
          margin-top: 40px;

          &::before {
            content: " ";
            display: block;
            width: 3.4rem;
            height: 8px;
            background-color: #ffffff;
            position: absolute;
            top: -4px;
            left: 30px;
          }
        }
      }
      p {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 1rem;
      }
      .last {
        margin-left: 32px;
        span:last-child {
          display: block;
          margin-left: 3rem;
        }
      }
      .icons {
        display: inline-block;
        position: relative;
        width: 25px;
        height: 25px;
        margin-right: 8px;
        background: url($QNHost+"/Link/sprit.png") no-repeat;
        &.icon-addr {
          background-position: -24px 0;
          width: 20px;
          margin-right: 14px;
        }
        &.icon-phone {
          background-position: -42px 0;
        }
      }
    }
    .imgs {
      position: absolute;
      top: 20%;
      width: 30%;
      right: 10%;
      display: flex;
      align-items: center;
      img {
        text-align: center;
      }
      > div {
        &:first-child {
          margin-right: 50px;
        }
      }
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 120px 0 20px;
}
.headtext {
  color: #37466a;
  font-size: 20px;
  margin-bottom: 50px;
}
.bottom {
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
  line-height: 40px;
}
.inputtext {
  margin-top: 20px;
  margin-bottom: 25px;
}
.inputtext input {
  height: 34px;
  width: 250px;
  font-size: 14px;
  margin-right: 30px;
  text-indent: 1em;
}
.countryed input {
  margin-left: 20px;
  margin-right: 5px;
}
.yours {
  margin-top: 20px;
}
.yours input {
  margin-left: 20px;
  margin-right: 5px;
}
.button {
  width: 290px;
  height: 40px;
  background-color: #00448b;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 80px;
  font-size: 20px;
  text-align: center;
  border-radius: 10px;
}
.background-media {
  background-image: url($QNHost+"/Link/5.jpg");
}

@media screen and (max-width: 750px) {
  .link {
    .top {
      margin-bottom: 1.5rem;
      .con {
        h5 {
          font-size: 4.8rem;
        }
        p {
          font-size: 3.1rem;
          &.line {
            height: 0.9rem;
          }
          &.en {
            margin-top: 6.7rem;
          }
        }
      }
    }
    .middle .title {
      top: 0;
      h5 {
        font-size: 2.4rem;
        height: 5rem;
        line-height: 6rem;
        &::before,
        &::after {
          width: 6.8rem;
          top: 3rem;
        }
        &::before {
          right: calc(50% - 14rem);
        }
        &::after {
          left: calc(50% - 14rem);
        }
      }
      p {
        font-size: 1.7rem;
      }
    }
    .background-media {
      background-image: url($QNHost+"/Link/mobile/05.png");
    }
    .tel {
      margin-left: 0px;
    }
    .tel1 {
      margin-left: 0px;
    }
    .tel2 {
      margin-left: 0px;
    }
    .tel3 {
      margin-left: 0px;
    }

    .addr {
      img {
        min-height: auto;
        height: 42rem;
      }
      .info .con {
        padding: 16px;
        p {
          margin: 0;
          display: block;
          &.address {
            font-size: 2.1rem;
          }
        }
        .title {
          font-size: 2.5rem;
        }
        .line {
          margin-top: 4rem;
        }
        .tel {
          margin-left: 2rem;
        }
        span {
          display: inline-block;
          width: auto;
        }
        .icons.icon-addr {
          width: 2rem;
          height: 3rem;
          background-size: 6rem;
          background-position: -2.1rem 0;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
      }
    }
  }
  .form {
    padding: 7.9rem 0 6.5rem;
  }
  .headtext {
    margin-bottom: 5rem;
    font-size: 2.8rem;
  }
  .bottom {
    width: 100%;
    padding: 0 3rem;
    font-size: 2rem;
    line-height: 4rem;
  }
  .inputtext {
    display: flex;
    margin: 2.1rem 0;
    justify-content: space-between;
    span {
      display: none;
    }
    input {
      width: 29.7rem;
      height: 4.9rem;
      border: 1px solid #1b1b1b;
      margin: 0;
    }
  }
  .countryed {
    margin-bottom: 2.5rem;
    > div {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        margin-right: 5rem;
      }
    }
    input {
      margin-left: 0px;
    }
  }
  .yours {
    margin-top: 2.5rem;
    > div {
      margin-top: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .yours input {
    margin-left: 0px;
  }
  .button {
    margin: 7.9rem auto 0;
    height: 5.4rem;
    line-height: 5.4rem;
  }
}
</style>
